import React, { useEffect, useState } from "react";
import Card from "components/card";
import ToggleSwitch from "components/toggleSwitch";
import marketplace_ticket_api from "services/marketplace_ticket";
import { useTranslation } from "react-i18next";

const CardContent = ({ title, text, children, enabled }) => {
  return (
    <>
      <div className="card-content-info">
        <div className="card-content-text">
          <h6>{title}</h6>
          {text && <p>{text}</p>}
        </div>
      </div>
      <div className={`card-content-child ${enabled ? "enabled" : ""}`}>
        {children}
      </div>
    </>
  );
};

const TransfersConfiguration = ({ eventTransferConfiguration }) => {
  const [enableTransfers, setEnableTransfers] = useState(false);
  const [enableCourtesiesTransfers, setEnableCourtesiesTransfers] = useState(false);

  const { t } = useTranslation("tickets");

  const updateEventTransferConfiguration = async (allowTicketTransfer, allowCourtesyTransfer) => {
    let apiUrl = `api/v1/event-transfer-settings/update/${eventTransferConfiguration.id}`;
    try {
      const { id, ...rest } = eventTransferConfiguration;
      const data = { ...rest, allowTicketTransfer, allowCourtesyTransfer };

      marketplace_ticket_api.put(apiUrl, data).then((res) => {
        setEnableTransfers(res.data.allowTicketTransfer);
        setEnableCourtesiesTransfers(res.data.allowCourtesyTransfer);
      });
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
    }
  };

  const handleToggleTransfer = () => {
    updateEventTransferConfiguration(!enableTransfers, enableCourtesiesTransfers);
  };

  const handleToggleCourtesyTransfer = () => {
    updateEventTransferConfiguration(enableTransfers, !enableCourtesiesTransfers);
  };

  useEffect(() => {
    setEnableTransfers(eventTransferConfiguration.allowTicketTransfer);
    setEnableCourtesiesTransfers(eventTransferConfiguration.allowCourtesyTransfer);
  }, [eventTransferConfiguration]);

  return (
    <div className="configurations-container">
      <Card>
        <CardContent
          title={t("nominalTransfers.configuration.title")}
          text={t("nominalTransfers.configuration.text")}
          enabled={enableTransfers}
        >
          {enableTransfers
            ? t("nominalTransfers.configuration.enabled")
            : t("nominalTransfers.configuration.disabled")}
          <ToggleSwitch checked={enableTransfers} onChange={handleToggleTransfer} />
        </CardContent>
      </Card>

      <Card>
        <CardContent
          title={t("nominalTransfers.configuration.courtesyTitle")}
          text={t("nominalTransfers.configuration.courtesyText")}
          enabled={enableCourtesiesTransfers}
        >
          {enableCourtesiesTransfers
            ? t("nominalTransfers.configuration.enabled")
            : t("nominalTransfers.configuration.disabled")}
          <ToggleSwitch checked={enableCourtesiesTransfers} onChange={handleToggleCourtesyTransfer} />
        </CardContent>
      </Card>
    </div>
  );
};

export default TransfersConfiguration;
