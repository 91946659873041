import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FaRegTimesCircle } from "react-icons/fa";

export default function RefuseButton(props) {
  const { title } = props;

  return (
    <ActionButton block variant="danger" {...props}>
      {title ? (
        title
      ) : (
        <>
          <FaRegTimesCircle className="mr-2" /> recusar transferência
        </>
      )}
    </ActionButton>
  );
}

//------ Styled Components -----//
const ActionButton = styled(Button)`
  svg {
    font-size: 24px;
  }
`;
