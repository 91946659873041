import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

DataTip.propTypes = {
	tipContent: PropTypes.string
};

export default function DataTip(props) {
	const { tipContent } = props;
	return (
		<span className="tips">
			<FontAwesomeIcon icon={faInfoCircle} />
			<div className="tips-content">{tipContent}</div>
		</span>
	);
}
