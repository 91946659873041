import {
  faUserCircle,
  faSignOutAlt,
  faEllipsisV,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line import/no-unresolved
import { ImageHandlerService } from "services/image-handler";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import React, { useState } from "react";
// eslint-disable-next-line import/no-unresolved
import api from "services/api";
import ModalQrcodeProducer from "pages/common-area/events/modalQrcodeProducer";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function UserMenu() {
  const { t } = useTranslation("components", {
    keyPrefix: "header",
  });
  const [modalPublicIsOpen, setModalPublicIsOpen] = useState(false);
  const [user] = React.useState(
    JSON.parse(localStorage.getItem("user") || "{}")
  );
  let pictureUrl, UserImg;
  function logout(event) {
    api
      .delete("logout")
      .then(() => {})
      .catch(() => {
        Swal.fire({
          title: "Erro",
          icon: "error",
          text: "Ocorreu um erro. Tente novamente.",
          confirmButtonText: "Ok",
        });
        event.currentTarget.disabled = false;
      })
      .finally(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    event.currentTarget.disabled = true;
  }

  if (user && user.picture) {
    pictureUrl = user.picture;
  } else if (localStorage.getItem("picture") !== null) {
    pictureUrl = localStorage.getItem("picture");
  }

  if (pictureUrl) {
    UserImg = ImageHandlerService.getImageFromUrl(pictureUrl, {
      format: "webp",
      resize: { width: 80, fit: "contain" },
    });
  } else {
    UserImg = "../../avatar.jpg";
  }
  return (
    <div id="usermenu" className="d-flex">
      <div id="header-userimg">
        <div
          className="img-user shadow-sm"
          style={{
            backgroundImage: `url(${UserImg})`,
          }}
        />
      </div>

      <Menu
        menuButton={
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            className="dotsHeader d-flex align-items-center mr-3"
          >
            <FontAwesomeIcon icon={faEllipsisV} style={{ marginLeft: 10 }} />
          </button>
        }
      >
        <a href="/user-data" className="dropdown-item">
          <FontAwesomeIcon icon={faUserCircle} /> {t("modalOptions.profile")}
        </a>
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            padding: "none",
          }}
          className="dropdown-item"
          onClick={() => setModalPublicIsOpen(true)}
        >
          <FontAwesomeIcon icon={faQrcode} /> {t("modalOptions.userQrcode")}
        </button>
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            padding: "none",
          }}
          onClick={logout}
          className="dropdown-item"
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
          {t("modalOptions.logout")}
        </button>
      </Menu>
      <ModalQrcodeProducer
        toggleFunction={{ modalPublicIsOpen, setModalPublicIsOpen }}
        user={user}
      />
    </div>
  );
}
