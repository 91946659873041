import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBan,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import CustomButtonSpinner from "components/buttonSpinner";
import Loading from "components/loading";
import PageHeader from "components/page-header";
import api from "services/api";
import {
  translatedOrderStatusNames,
  translatedPaymentMethods,
} from "services/constants";

import ModalVendas from "./modalVendas/index";

import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils";
import { dateFormatter } from "utils/date-formatter";
import "./style.css";

let container;
let porPagina = 25;

export default function Vendas() {
  const {
    t,
    i18n: { language },
  } = useTranslation(["tickets"]);
  const [statusList, setStatusList] = useState([]);

  const [dataSales, setdataSales] = useState([]);
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });
  const [statusTransicao, setstatusTransicao] = useState(null);
  const [tipoPagamento, settipoPagamento] = useState(null);
  const [showModalVendas, setShowModalVendas] = useState(false);
  const [compradorId, setCompradorId] = useState(null);
  const [ordBy, setOrdBy] = useState({
    column: "data",
    ord: "desc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [payment_type, setPayment_type] = useState("");
  const [situation_rate, setSituationRate] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [orderNumber] = useState(urlParams.get("orderNumber"));
  const { currency } = useCurrencyEventContext();

  function rotate(element, value) {
    let rotated = document.getElementsByClassName("rotated");
    let active = document.getElementsByClassName("arrow-active");
    for (let index = 0; index < rotated.length; index++) {
      rotated[index].classList.remove("rotated");
    }
    for (let index = 0; index < active.length; index++) {
      active[index].classList.remove("arrow-active");
    }
    element.target.classList.add("arrow-active");

    if (ordBy.ord === "asc" && ordBy.column === value) {
      element.target.classList.add("rotated");
      setOrdBy({ column: value, ord: "desc" });
    } else {
      element.target.classList.remove("rotated");
      setOrdBy({ column: value, ord: "asc" });
    }
  }

  useEffect(() => {
    if (orderNumber) {
      detailsSalesEvent("navigation", 1, porPagina, orderNumber);
      setPesquisa({ pesquisa: orderNumber });
    } else {
      detailsSalesEvent("initial", 1, porPagina, pesquisa.pesquisa);
    }
    if (document.getElementById("loading")) {
      document.getElementById("loading").classList.add("esconde");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordBy, language]);

  const [usuarioLogado /*setUsuarioLogado*/] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const detailsSalesEvent = (typePage, numberPage, perPage, search) => {
    setIsLoading(true);
    let pageConsult = {
      page: numberPage,
      perPage: perPage,
      search: search,
      status: statusTransicao,
      tipoPagamento: tipoPagamento,
      ordBy: ordBy,
    };

    switch (typePage) {
      case "Initial":
        pageConsult = {
          page: 1,
          perPage: perPage,
          search: pesquisa.pesquisa,
          status: statusTransicao,
          tipoPagamento: tipoPagamento,
          ordBy: ordBy,
        };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            status: statusTransicao,
            tipoPagamento: tipoPagamento,
            ordBy: ordBy,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            status: statusTransicao,
            tipoPagamento: tipoPagamento,
            ordBy: ordBy,
          };
        }
        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          status: statusTransicao,
          tipoPagamento: tipoPagamento,
          ordBy: ordBy,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
            status: statusTransicao,
            tipoPagamento: tipoPagamento,
            ordBy: ordBy,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          status: statusTransicao,
          tipoPagamento: tipoPagamento,
          ordBy: ordBy,
        };
        break;
      case "navigation":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: search,
          status: statusTransicao,
          tipoPagamento: tipoPagamento,
          ordBy: ordBy,
        };
        break;
      default:
        pageConsult = {
          page: 1,
          perPage: perPage,
          search: pesquisa.pesquisa,
          status: statusTransicao,
          tipoPagamento: tipoPagamento,
          ordBy: ordBy,
        };
    }

    api
      .post(`detailsSalesEvent/${event_id}`, pageConsult)
      .then((res) => {
        const statuses = res.data.statusList
          .map(({ status }) => ({
            status: status,
            label: t(`constants:${translatedOrderStatusNames[status]}`),
          }))
          .sort((a, b) =>
            a.label === b.label ? 0 : a.label < b.label ? -1 : 1
          );

        setdataSales(res.data.loadingPaginationSalesEvent.data);
        setAtualizaPaginaAtual(res.data.loadingPaginationSalesEvent.page);
        setAtualizaUltimaPagina(res.data.loadingPaginationSalesEvent.lastPage);
        setAtualizaQuantPesquisa(res.data.loadingPaginationSalesEvent.total);
        setStatusList(statuses);
        setIsLoading(false);

        if (
          typePage === "navigation" &&
          res.data.loadingPaginationSalesEvent.data.length > 0
        ) {
          const firstSale = res.data.loadingPaginationSalesEvent.data[0];
          openModalVendas(
            firstSale.id,
            firstSale.payment_type,
            firstSale.situation_rate
          );
        }
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error(t("sales.detailedExtract.notFoundRoute"));
        } else {
          container.error(err.response.error.message);
        }
        document.getElementById("loading").classList.add("esconde");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      detailsSalesEvent("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };
  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };
  function openModalVendas(id, payment_type, situation_rate) {
    setCompradorId(id);
    setPayment_type(payment_type);
    setSituationRate(situation_rate);
    setShowModalVendas(true);
  }

  function handleVendasUpdates() {
    setShowModalVendas(false);
    setCompradorId(null);

    detailsSalesEvent(
      "lastPage",
      atualizaPaginaAtual,
      porPagina,
      pesquisa.pesquisa
    );
  }

  return (
    <>
      <Loading
        title={t("global:wait") + "..."}
        text={t("sales.detailedExtract.loadingEventSales")}
      />

      <PageHeader
        title={t("sales.detailedExtract.title")}
        text={t("sales.detailedExtract.subtitle")}
      ></PageHeader>

      <div
        className="pagetype-tickets container-fluid mt-4"
        style={{ paddingBottom: "30px" }}
      >
        <div className="col-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  className="input-custom"
                  name="pesquisa"
                  placeholder={t("sales.detailedExtract.searchByBuyerCodeMail")}
                  onChange={updateData}
                  autoComplete="off"
                  value={pesquisa.pesquisa || ""}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="col-md-3 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <select
                  type="text"
                  className="input-custom"
                  name="code"
                  onChange={(e) => setstatusTransicao(e.target.value)}
                  placeholder={t("sales.detailedExtract.searchByStatus")}
                  onKeyDown={handleKeyDown}
                >
                  <option value="" key={0}>
                    {t("sales.detailedExtract.selectStatus")}
                  </option>
                  {statusList.map((status, index) => (
                    <option key={index} value={status.status}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-3 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <select
                  type="text"
                  className="input-custom"
                  name="code"
                  onChange={(e) => settipoPagamento(e.target.value)}
                  placeholder={t("sales.detailedExtract.searchByPaymentType")}
                  onKeyDown={handleKeyDown}
                >
                  <option value="">
                    {t("sales.detailedExtract.selectPaymentType")}
                  </option>
                  {translatedPaymentMethods.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {t(`constants:${label}`)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-2 mb-2">
              <CustomButtonSpinner
                customStyle={"btn btn-outline-secondary btn-height"}
                value={pesquisa.pesquisa || ""}
                triggerOnClick={detailsSalesEvent}
                isLoading={isLoading}
                text={t("global:search")}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table
                id="table-totalsales"
                className="table table-sm card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("sales.detailedExtract.orderCode")}</th>
                    <th
                      scope="col"
                      className="text-center arrow-active"
                      onClick={(e) => rotate(e, "data")}
                    >
                      {t("sales.detailedExtract.date")}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      onClick={(e) => rotate(e, "comprador")}
                    >
                      {t("sales.detailedExtract.buyer")}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </th>
                    <th
                      scope="col"
                      className="text-center "
                      onClick={(e) => rotate(e, "status")}
                    >
                      {t("sales.detailedExtract.status")}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      onClick={(e) => rotate(e, "pagamento")}
                    >
                      {t("sales.detailedExtract.paymentType")}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </th>
                    <th
                      scope="col"
                      className="text-right"
                      onClick={(e) => rotate(e, "valor")}
                    >
                      {t("sales.detailedExtract.value")}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {dataSales.length ? (
                    dataSales.map((sales, i) => {
                      // Definição sobre cor das badges
                      const badgeStatus =
                        sales.status === "finalizado"
                          ? "bg-success"
                          : sales.status === "pendente"
                          ? "bg-warning"
                          : sales.status === "estornado" ||
                            sales.status === "charged_back"
                          ? "bg-danger"
                          : "bg-secondary-soft";
  
                      // Renderização
                      return (
                        <tr
                          key={i}
                          onClick={() =>
                            openModalVendas(
                              sales.id,
                              sales.payment_type,
                              sales.situation_rate
                            )
                          }
                        >
                          <td>{sales.token_ticket}</td>
                          <td className="text-center">
                            {dateFormatter(sales.created_at, {
                              locale: language,
                              shouldShowTime: true,
                            })}
                          </td>
                          <td className="text-center">
                            {sales.first_name + " " + sales.last_name}
                          </td>
                          <td className="text-center">
                            <span className={badgeStatus + " badge badge-pill"}>
                              {t(
                                `constants:${
                                  translatedOrderStatusNames[sales.status]
                                }`
                              ) || sales.status}
                            </span>
                          </td>
                          <td className="text-center payment-type">
                            {sales.payment_type ===
                            t("sales.detailedExtract.paymentBillet") ? (
                              <>
                                <img
                                  src={
                                    window.location.origin + "/cartoes/boleto.svg"
                                  }
                                  className="payment-icon"
                                  alt=""
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : sales.payment_type ===
                              t("sales.detailedExtract.cash") ? (
                              <>
                                <img
                                  src={
                                    window.location.origin +
                                    "/cartoes/dinheiro.svg"
                                  }
                                  className="payment-icon"
                                  alt=""
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : sales.payment_type.includes("Cartão") ? (
                              <>
                                <img
                                  src={
                                    window.location.origin + "/cartoes/cartao.svg"
                                  }
                                  className="payment-icon"
                                  alt=""
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : // <img src={bandeira(sales.flag_card)} className="payment-icon" alt="" />
                            sales.payment_type === "PIX" ? (
                              <>
                                <img
                                  src={
                                    window.location.origin + "/cartoes/pixx.svg"
                                  }
                                  className="payment-icon"
                                  alt=""
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : sales.payment_type === "picpay" ? (
                              <>
                                <img
                                  src={
                                    window.location.origin + "/cartoes/picpay.svg"
                                  }
                                  className="payment-icon"
                                  alt=""
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : sales.payment_type.includes("Não") ? (
                              <>
                                <FontAwesomeIcon
                                  icon={faBan}
                                  className="payment-icon text-error "
                                />{" "}
                                {sales.payment_type}
                              </>
                            ) : (
                              sales.payment_type
                            )}
                          </td>
                          <td className="text-right">
                            {formatCurrency(sales.producer_value, {
                              currency,
                              isCents: true,
                            })}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                  <tr>
                    <td colspan="6" align="center" className="text-center">
                      <p>{t("nominalTransfers.emptyList")}</p>
                    </td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="col-12">
              <div className="row d-flex justify-content-between mb-3 datatable-footer">
                <div className="col-md-6 d-flex align-items-center">
                  <span className="mr-2 ">
                    {t("components:pagination.results")}
                  </span>
                  <div>
                    <select
                      name="SelecaoPorPagina"
                      onChange={(e) =>
                        detailsSalesEvent(
                          "initial",
                          1,
                          (porPagina = e.target.value)
                        )
                      }
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                  {porPagina * atualizaPaginaAtual >= atualizaQuantPesquisa ? (
                    <span>
                      {t("components:pagination.displaying", {
                        currentPageTotal: atualizaQuantPesquisa,
                        total: atualizaQuantPesquisa,
                      })}
                    </span>
                  ) : (
                    <span>
                      {t("components:pagination.displaying", {
                        currentPageTotal: atualizaPaginaAtual,
                        total: atualizaQuantPesquisa,
                      })}
                    </span>
                  )}
                  <button
                    onClick={() => detailsSalesEvent("initial", 1, porPagina)}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                  <button
                    onClick={() =>
                      detailsSalesEvent("left", atualizaPaginaAtual, porPagina)
                    }
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <span>
                    {t("components:pagination.page", {
                      currentPage: atualizaPaginaAtual,
                      totalPages: atualizaUltimaPagina,
                    })}
                  </span>
                  <button
                    onClick={() =>
                      detailsSalesEvent("right", atualizaPaginaAtual, porPagina)
                    }
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                  <button
                    onClick={() =>
                      detailsSalesEvent(
                        "lastPage",
                        atualizaUltimaPagina,
                        porPagina
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalVendas
        show={showModalVendas}
        onClose={() => setShowModalVendas(false)}
        compradorId={compradorId}
        eventId={event_id}
        payment_type={payment_type}
        situation_rate={situation_rate}
        usuarioLogado={usuarioLogado}
        onRefund={handleVendasUpdates}
      />
    </>
  );
}
