import React from "react";
import { CardsContainer, ContentContainer } from "./styles";
import Table from "../table";
import CustomCarousel from "components/custom-carousel";
import { BorderoCard } from "components/bordero-card";
import { useScreenWidth } from "customerHooks/useScreenWidth";

export const ContainerContent = ({ borderoCardData, tableData }) => {
  const isMobile = useScreenWidth();
  return (
    <ContentContainer>
      <CardsContainer>
        {isMobile ? (
          <CustomCarousel elements={borderoCardData}>
            {borderoCardData.map((card, cardIndex) => (
              <BorderoCard
                key={card.id || cardIndex}
                amount={card.amount}
                description={card.description}
                tooltip={card.tooltip}
              />
            ))}
          </CustomCarousel>
        ) : (
          borderoCardData?.map((sale) => (
            <BorderoCard
              key={sale.id}
              amount={sale.amount}
              description={sale.description}
              tooltip={sale.tooltip}
            />
          ))
        )}
      </CardsContainer>
      {tableData?.tables.map((row, index) => (
        <Table
          key={index}
          columns={row.columns}
          title={row.title}
          rows={row.rows}
          total={row.total}
        />
      ))}
    </ContentContainer>
  );
};
