import styled from "styled-components";

export const Container = styled.div`
  display: grid;
`;

export const Main = styled.main`
  padding: 1rem 1.5rem;
  width: 100%;
  display: grid;
  grid-template-areas: "table summary";
  grid-template-columns: 1fr min-content;
  gap: 1rem;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 1rem;

  .datepicker-container {
    max-width: 180px;
  }

  .export-text {
    @media (max-width: 680px) {
      display: none;
    }
  }
`;
