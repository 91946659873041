import { faExclamation, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as yup from "yup";

import ContainerFluid from "components/containers/container-fluid";
import EventAutocomplte from "components/inputeventautocomplete";
import SideModal from "components/side-modal";
import SuperRadioButton from "components/super-radiobutton";
import { numberMask, phoneMask, cpfMask } from "mask";
import Api from "services/api";
import { destinyKeys, finalityKeys } from "services/constants";
import { IpService } from "services/ip";
import New_api from "services/new_api";
import { ValidationsHelper } from "services/validations";
import { useLanguageSettings } from "hooks/language-settings";

const transferFormSchema = (isAdmin, maxValue) =>
  yup.object({
    method: yup
      .string()
      .default("PIX")
      .oneOf(["PIX", "TED"])
      .required("A forma de transferência deve ser informada."),
    name: yup.string().trim().required("O destinatário deve ser informado."),
    observation: yup
      .string()
      .trim()
      .max(1024, "A observação deve ter no máximo 1024 caracteres."),
    amount: yup
      .string()
      .required("O valor deve ser informado.")
      .test(
        "min",
        "O valor deve ser maior que 0.",
        (val) =>
          parseFloat(
            val
              .replace(/[^0-9.,-]+/g, "")
              .replace(".", "")
              .replace(",", ".")
          ) > 0
      )
      .test(
        "max",
        "O valor solicitado deve ser menor ou igual ao saldo do evento.",
        (val) => parseFloat(val.replace(/[^0-9.,-]+/g, "")) <= maxValue
      ),
    type_account: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
              .required("O tipo de chave deve ser informado.")
              .oneOf(["email", "phone", "key", "document"])
          : field
              .required("O tipo da conta deve ser informado.")
              .oneOf(["savings", "checking"])
      ),
    document: yup
      .string()
      .trim()
      .required("O documento deve ser informado.")
      .test(
        "documentTest",
        "Informe um documento válido.",
        (value) =>
          value &&
          (ValidationsHelper.isCpfValid(value) ||
            ValidationsHelper.isCnpjValid(value))
      ),
    bank_id: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required("O banco deve ser informado.")
      ),
    agency: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("A agência deve ser informada.")
      ),
    account: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("O conta bancária deve ser informada.")
      ),
    account_digit: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("O dígito deve ser informado.")
      ),
    origin: isAdmin
      ? yup
          .string()
          .required("A origem da transferência deve ser informada.")
          .oneOf(["ticket", "a&b"])
      : yup.string().notRequired(),
    category: isAdmin
      ? yup
          .string()
          .required("A categoria da transferência deve ser informada.")
          .oneOf(["advance", "transfer"])
      : yup.string().notRequired(),
    finality: yup
      .string()
      .required("A finalidade da transferência deve ser informada.")
      .oneOf([
        "artist",
        "air",
        "food",
        "marketing",
        "bar",
        "structure",
        "operation",
        "online_anticipation",
        "pos_anticipation",
      ]),
    destiny: yup
      .string()
      .oneOf(["organizer", "third party"])
      .required("O destino de conta é obrigatório."),
    pix_key: yup
      .string()
      .trim()
      .when("type_account", (type, field) => {
        if (type === "key") {
          return field.required("A chave deve ser informada.");
        } else if (type === "phone") {
          return field
            .required("O telefone deve ser informado.")
            .matches(
              /^\((\d{2})\) \D*(\d{5}|\d{4})\D*(\d{4})$/,
              "Deve ser informado um telefone válido."
            );
        } else if (type === "email") {
          return field
            .email("Deve ser informado um e-mail válido.")
            .required("O e-mail deve ser informado.");
        } else if (type === "document") {
          return field
            .required("O documento deve ser informado.")
            .test(
              "documentTest",
              "Informe um documento válido.",
              (value) =>
                value &&
                (ValidationsHelper.isCpfValid(value) ||
                  ValidationsHelper.isCnpjValid(value))
            );
        }
      }),
  });

const pixKeys = {
  document: "CPF ou CNPJ",
  email: "E-mail",
  phone: "Celular",
  key: "Chave aleatória",
};

const originKeys = {
  ticket: "Ticket",
  "a&b": "A&B",
};

const categoryKeys = {
  advance: "Adiantamento",
  transfer: "Transferência",
};

function pixKeyPlaceholder(key) {
  switch (key) {
    case "phone":
      return "Telefone com DDD";
    case "email":
      return "email@provedor.com.br";
    case "key":
      return "Insira a chave aleatória";
    case "document":
      return "Insira um CPF ou CNPJ";
    default:
      return "";
  }
}

function ValueField(props) {
  const { register, error, onChange } = props;

  return (
    <Form.Group as={Col} md="6">
      <Form.Label>Valor</Form.Label>
      <Form.Control
        {...register("amount")}
        isInvalid={error}
        placeholder="Valor da transferência"
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

function DocumentField(props) {
  const { title, register, placeholder, error, onChange, value } = props;

  return (
    <Form.Group as={Col} md="6">
      <Form.Label>{title}</Form.Label>
      <CpfCnpj
        {...register("document")}
        className={`form-control ${error ? "is-invalid" : ""}`}
        placeholder={placeholder || "Insira um CPF ou CNPJ"}
        value={value}
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

CreateTransferModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  eventId: PropTypes.number,
  maxValue: PropTypes.number,
  eventName: PropTypes.string,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default function CreateTransferModal(props) {
  const { currencyInputFormatter } = useLanguageSettings();
  const {
    show,
    title,
    maxValue,
    eventName,
    eventId,
    onClose,
    onError,
    onSuccess,
    currency,
  } = props;

  const [banks, setBanks] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customEvent, setCustomEvent] = useState("");
  const [customEventId, setCustomEventId] = useState(eventId);
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    reset,
    watch,
    register,
    setValue,
    resetField,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      transferFormSchema(
        !!user.is_admin,
        maxValue !== null ? maxValue : Number.MAX_SAFE_INTEGER
      )
    ),
  });

  const method = watch("method");
  const pixKey = watch("type_account");
  const document = watch("document");

  const handleSelectEvent = (event) => {
    setCustomEventId(event.id);
  };

  const selectBank = (e) => setValue("bank_id", e.value);

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleFormData = async (form) => {
    setLoading(true);

    let ip;

    try {
      ip = await IpService.getMyIp();
    } catch {}

    let data = {
      ...form,
      amount: numberMask(form.amount),
      ip,
    };

    if (data.method === "PIX") {
      data = {
        ...data,
        agency: null,
        account: null,
        account_digit: null,
        bank_id: null,
      };
    }

    if (!user.is_admin) {
      data = {
        ...data,
        origin: null,
        category: "transfer",
      };
    }

    New_api.post(`/events/${eventId || customEventId}/transfers`, data)
      .then((result) => {
        onSuccess(result.data);
        onClose();
      })
      .catch((err) => onError(err))
      .finally(() => setLoading(false));
  };

  const handleDocumentValue = (event) => {
    let value = event.target.value;

    if (pixKey === "phone") {
      value = phoneMask(value);
    }

    if (pixKey === "document") {
      value = cpfMask(value);
    }

    setValue("pix_key", value);
  };

  const _fetchBanks = () => {
    Api.get("lista/bancos")
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.id,
          name: item.code + " - " + item.name,
        }));

        setBanks(data);
      })
      .catch(() => setBanks([]));
  };

  useEffect(() => {
    clearErrors();

    if (method) {
      setValue("type_account", method === "PIX" ? "document" : "checking");
      resetField("document");
    }
  }, [method, clearErrors, resetField, setValue]);

  useEffect(() => {
    reset({
      method: "PIX",
      type_account: "document",
      origin: "",
      category: "",
      finality: "",
      observation: "",
    });
    setChecked(false);
    setCustomEvent("");
    setCustomEventId(null);

    if (show) {
      _fetchBanks();
    }
  }, [show, reset]);

  return (
    <SideModal show={show} title={title.toUpperCase()} onClose={handleClose}>
      <ContainerFluid>
        <Row>
          <Col>
            <Alert variant="danger" className="mt-3 w-100">
              <span className="icon-circle-sm">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              {eventName ? (
                <span>
                  <b>Atenção!</b> Você está transferindo através dos recursos do
                  evento <b>{eventName}</b>.
                </span>
              ) : (
                <span>
                  <b>Atenção!</b> Você está transferindo através dos recursos{" "}
                  <b> da conta gestora</b>.
                </span>
              )}
            </Alert>
          </Col>
        </Row>
        <Form noValidate onSubmit={handleSubmit(handleFormData)}>
          {!eventId && (
            <Form.Row>
              <Form.Group as={Col} md="12" className="mb-0">
                <EventAutocomplte
                  value={customEvent}
                  onChange={setCustomEvent}
                  onSelect={handleSelectEvent}
                />
              </Form.Group>
            </Form.Row>
          )}

          {customEventId && <hr />}

          {(eventId || customEventId) && (
            <>
              <Form.Row>
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    requiredValue="PIX"
                    value={method}
                    label="PIX"
                    callback={() => setValue("method", "PIX")}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    value={method}
                    requiredValue="TED"
                    label="TED"
                    callback={() => setValue("method", "TED")}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    {method === "PIX" ? "Destinatário" : "Nome completo"}
                  </Form.Label>
                  <Form.Control
                    {...register("name")}
                    placeholder={
                      method === "PIX"
                        ? "Nome completo ou descrição"
                        : "Nome completo"
                    }
                    isInvalid={errors.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {method === "PIX" && (
                  <ValueField
                    register={register}
                    error={errors.amount?.message}
                    onChange={(e) =>
                      setValue(
                        "amount",
                        currencyInputFormatter(e.target.value, currency)
                      )
                    }
                  />
                )}
                <DocumentField
                  title="Documento do destinatário"
                  register={register}
                  value={document}
                  error={errors.document?.message}
                  onChange={(e) => setValue("document", e.target.value)}
                />
              </Form.Row>

              {method === "PIX" ? (
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Tipo da Chave</Form.Label>
                    <Form.Control
                      as="select"
                      {...register("type_account")}
                      isInvalid={errors.type_account?.message}
                    >
                      <option value="" disabled>
                        Selecione o tipo da chave
                      </option>
                      {Object.keys(pixKeys).map((key) => (
                        <option key={key} value={key}>
                          {pixKeys[key]}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.type_account?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Chave PIX</Form.Label>
                    <Form.Control
                      {...register("pix_key")}
                      placeholder={pixKeyPlaceholder(pixKey)}
                      isInvalid={errors.pix_key?.message}
                      onChange={handleDocumentValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pix_key?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              ) : (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Banco</Form.Label>
                      <Select
                        options={banks.map((bank) => ({
                          value: bank.value,
                          label: bank.name,
                        }))}
                        onChange={selectBank}
                        placeholder="Banco"
                        isInvalid={errors.bank_id?.message}
                      />
                      {errors.bank_id?.message && (
                        <ErrorFont className="text-danger mt-1">
                          É necessário selecionar um banco.
                        </ErrorFont>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Tipo da Conta</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("type_account")}
                        isInvalid={errors.type_account?.message}
                      >
                        <option value="" disabled>
                          Selecione o tipo da conta
                        </option>
                        <option value="checking">Corrente</option>
                        <option value="savings">Poupança</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.type_account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="2">
                      <Form.Label>Agência</Form.Label>
                      <Form.Control
                        {...register("agency")}
                        maxLength="4"
                        placeholder="Sem dígito"
                        isInvalid={errors.agency?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.agency?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" className="col-8">
                      <Form.Label>Conta</Form.Label>
                      <Form.Control
                        {...register("account")}
                        placeholder="Sem dígito"
                        type="number"
                        isInvalid={errors.account?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="1" className="col-4">
                      <Form.Label>Dígito</Form.Label>
                      <Form.Control
                        {...register("account_digit")}
                        maxLength="1"
                        isInvalid={errors.account_digit?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account_digit?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <ValueField
                      register={register}
                      error={errors.amount?.message}
                      onChange={(e) =>
                        setValue(
                          "amount",
                          currencyInputFormatter(e.target.value, currency)
                        )
                      }
                    />
                  </Form.Row>
                </>
              )}
              {eventName ? null : (
                <>
                  <hr></hr>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Categoria</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("category")}
                        isInvalid={errors.category?.message}
                      >
                        <option value="" disabled>
                          Selecione a categoria da transferência
                        </option>
                        {Object.keys(categoryKeys).map((key) => (
                          <option key={key} value={key}>
                            {categoryKeys[key]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.category?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label>Origem</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("origin")}
                        isInvalid={errors.origin?.message}
                      >
                        <option value="" disabled>
                          Selecione a origem da transferência
                        </option>
                        {Object.keys(originKeys).map((key) => (
                          <option key={key} value={key}>
                            {originKeys[key]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.origin?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Finalidade</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("finality")}
                    isInvalid={errors.finality?.message}
                  >
                    <option value="" disabled>
                      Selecione a finalidade da transferência
                    </option>
                    {Object.keys(finalityKeys).map((key) => (
                      <option key={key} value={key}>
                        {finalityKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.finality?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Destino</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("destiny")}
                    isInvalid={errors.destiny?.message}
                  >
                    <option value="">
                      Selecione o destino da transferência
                    </option>
                    {Object.keys(destinyKeys).map((key) => (
                      <option key={key} value={key}>
                        {destinyKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.destiny?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Observação</Form.Label>
                  <Form.Control
                    style={{ resize: "none" }}
                    {...register("observation")}
                    name="observation"
                    autoComplete="nope"
                    type="textarea"
                    as="textarea"
                    rows={3}
                    placeholder="Observação da transferência"
                    isInvalid={errors.observation?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.observation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <TermsContainer onClick={() => setChecked(!checked)}>
                <Row noGutters>
                  <Col xs="2" md="1" className="d-flex justify-content-center">
                    <TermsCheckbox accepted={checked}>
                      <TermsCheckmark show={checked}>
                        <TermsCheckmarkStem></TermsCheckmarkStem>
                        <TermsCheckmarkKick></TermsCheckmarkKick>
                      </TermsCheckmark>
                    </TermsCheckbox>
                  </Col>
                  <Col md="10">
                    <TermsTitle>
                      Ao prosseguir com essa solicitação de transferência, eu
                      declaro concordar com os{" "}
                      <TermsLink target="_blank" to="/termos-de-uso">
                        termos de uso da plataforma.
                      </TermsLink>
                    </TermsTitle>
                    <TermsSubtitle>
                      Essa transferência, bem como o destino dado a ela,
                      conforme descrito nos termos de uso, é de inteira
                      responsabilidade do solicitante, assim como o recolhimento
                      de tributos relacionados à receita de vendas do site e
                      responsabilidade com as leis anti corrupção.
                    </TermsSubtitle>
                  </Col>
                </Row>
              </TermsContainer>

              <Row className="justify-content-end mt-3 mb-3">
                <Col md="5">
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={!checked || loading}
                  >
                    {loading ? (
                      "Enviando"
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                        {" " + title}
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </ContainerFluid>
    </SideModal>
  );
}

//------- Styled Components ------//
const TermsContainer = styled.div`
  border: 2px solid #e6f0f6;
  border-radius: 8px;
  padding: 16px 0;
  background: #f1f9f9;
`;

const TermsTitle = styled.p`
  font-size: 13px;
  color: #4f6c7c;
  margin: 0 0 5px;
  font-weight: 400;
`;

const TermsSubtitle = styled.p`
  font-weight: 300;
  color: #8fa5b2;
  font-size: 11px;
  margin: 0;
`;

const TermsCheckmark = styled.span`
  display: ${(props) => (props.show ? "inline-block" : "none")};
  width: 38px;
  height: 9px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

const TermsCheckmarkStem = styled.div`
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: #ffffff;
  left: 9px;
  top: 3px;
`;

const TermsCheckmarkKick = styled.div`
  position: absolute;
  width: 6px;
  height: 3px;
  background-color: #ffffff;
  left: 4px;
  top: 12px;
`;

const TermsCheckbox = styled.div`
  width: 19px;
  height: 19px;
  border: 2px solid rgb(79 108 124 / 59%);
  border-radius: 3px;
  margin: 5px 0;
  cursor: pointer;
  background-color: ${(props) => (props.accepted ? "#00a7e5" : "none")};
  border-color: ${(props) =>
    props.accepted ? "#00a7e5" : "rgb(79 108 124 / 59%)"};
`;

const TermsLink = styled(Link)`
  text-decoration: underline;
  color: #2075f4;
`;

const ErrorFont = styled.span`
  font-size: 13px;
`;
