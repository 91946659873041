import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";

export default function ApproveButton(props) {
  const { title } = props;

  return (
    <ActionButton block variant="success" {...props}>
      <>
        <FaRegCheckCircle className="mr-2" />
        {title ? title : "aprovar transferência"}
      </>
    </ActionButton>
  );
}

//------ Styled Components -----//
const ActionButton = styled(Button)`
  svg {
    font-size: 24px;
  }
`;
