import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "components/page-header";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faChartPie } from "@fortawesome/free-solid-svg-icons";
import Loading from "components/loading/index";
import moment from "moment-timezone";
import EventAutocomplte from "components/inputeventautocomplete";
import Api from "../../../services/api";
import Swal from "sweetalert2";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

export default function DashboardMaster() {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const { t } = useTranslation();
  moment.tz.setDefault("America/Sao_Paulo");
  const [dataInit, setDataInit] = useState(
    new Date(moment.tz("America/Sao_Paulo").startOf("month"))
  );
  const [dataFim, setDataFim] = useState(
    new Date(moment.tz("America/Sao_Paulo"))
  );
  const [fatMensal, setFatMensal] = useState(0);
  const [ticketMedio, setTicketMedio] = useState(0);
  const [fatProdutor, setFatProdutor] = useState(0);
  const [jurosParc, setjurosParc] = useState(0);
  const [taxa, setTaxa] = useState(0);
  const [eventID, setEventId] = useState("");
  const [detailsPayments, setDetailsPayments] = useState([]);
  const [value, setValue] = useState("");
  const [generalData, setGeneralData] = useState({
    dataInit: moment(
      new Date(moment.tz("America/Sao_Paulo").startOf("month"))
    ).format("YYYY-MM-DD "),
    dataFim: moment(new Date(moment.tz("America/Sao_Paulo"))).format(
      "YYYY-MM-DD "
    ),
    eventID,
  });

  // eslint-disable-next-line
  const user = JSON.parse(localStorage.getItem("user"));

  const currentMonth = useCallback(async (data) => {
    // eslint-disable-next-line
    document.getElementById("loading").classList.remove("esconde");

    Api.get(
      `dashboard/sistema?dataInit=${data.dataInit}&dataFim=${data.dataFim}&eventID=${data.eventID}`
    )
      .then((res) => {
        setFatMensal(res.data.faturamentoMensal);
        setTicketMedio(res.data.ticketMedio);
        setFatProdutor(res.data.faturamentoProdutor);
        setjurosParc(res.data.jurosParcelamento);
        setTaxa(res.data.taxaadm);
        // eslint-disable-next-line
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        // eslint-disable-next-line
        document.getElementById("loading").classList.add("esconde");
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });

    Api.post("dashboard/sistema/payments", data)
      .then((res) => {
        setDetailsPayments(res.data.revenues);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    // eslint-disable-next-line
  }, []);

  const seleciona = (time) => {
    // eslint-disable-next-line
    const now = document.getElementById("atual");
    // eslint-disable-next-line
    const old = document.getElementById("old");
    if (time === 0) {
      if (!now.classList.contains("active")) {
        now.classList.add("active");
      }
      old.classList.remove("active");
      setDataInit(new Date(moment.tz("America/Sao_Paulo").startOf("month")));
      setDataFim(new Date(moment.tz("America/Sao_Paulo")));
      setGeneralData({
        dataInit: moment(
          new Date(moment.tz("America/Sao_Paulo").startOf("month"))
        ).format("YYYY-MM-DD "),
        dataFim: moment(new Date(moment.tz("America/Sao_Paulo"))).format(
          "YYYY-MM-DD "
        ),
        eventID,
      });
    } else if (time === 1) {
      if (!old.classList.contains("active")) {
        old.classList.add("active");
      }
      now.classList.remove("active");
      setDataInit(
        new Date(
          moment.tz("America/Sao_Paulo").startOf("month").subtract(1, "months")
        )
      );
      setDataFim(
        new Date(
          moment.tz("America/Sao_Paulo").subtract(1, "months").endOf("month")
        )
      );
      setGeneralData({
        dataInit: moment(
          new Date(moment.tz("America/Sao_Paulo").startOf("month"))
        )
          .subtract(1, "months")
          .format("YYYY-MM-DD 00:00:00"),
        dataFim: moment(
          new Date(
            moment.tz("America/Sao_Paulo").subtract(1, "months").endOf("month")
          )
        ).format("YYYY-MM-DD 23:59:59"),
        eventID,
      });
    } else if (time === 2) {
      if (now.classList.contains("active")) {
        now.classList.remove("active");
      }
      if (old.classList.contains("active")) {
        old.classList.remove("active");
      }
    }
  };

  const searchByEvent = (event) => {
    if (event) {
      setEventId(event.id);

      setGeneralData({
        dataInit: "",
        dataFim: "",
        eventID: event.id,
      });
      seleciona(2);
    }
  };

  useEffect(() => {
    currentMonth(generalData);
    // eslint-disable-next-line
  }, [generalData]);

  return (
    <>
      <PageHeader
        title={`Olá,  ${user.first_name} ${user.last_name}`}
        text="Esse é o seu painel de organizador."
      />
      <Loading title="aguarde..." />
      <div id="manager-dashboard" className="container-fluid mt-4">
        <div className="container-fluid col-12">
          <EventAutocomplte
            value={value}
            onChange={setValue}
            onSelect={searchByEvent}
          />
        </div>

        <div className="col-12">
          {/* CARDS */}
          <div id="cards-dashboard" className="row dashboard-style mt-3 ">
            <div className="col-md-3 mb-3">
              <button
                type="button"
                id="atual"
                onClick={() => seleciona(0)}
                className="btn btn-outline-secondary active"
              >
                Mês Atual
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <button
                type="button"
                id="old"
                onClick={() => seleciona(1)}
                className="btn btn-outline-secondary"
              >
                Mês Anterior
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                value={dataInit}
                onFocus={() => seleciona(2)}
                onChange={(date) => {
                  setDataInit(date);
                  setGeneralData({
                    dataInit: moment(date)
                      .tz("America/Sao_Paulo")
                      .format("YYYY-MM-DD "),
                    dataFim: dataFim
                      ? moment(dataFim)
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD ")
                      : null,
                    eventID,
                  });
                }}
                placeholderText={t("components:datepicker.startDate")}
              />
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                value={dataFim}
                marginLeft={["-6rem", "-4rem", 0]}
                onFocus={() => seleciona(2)}
                onChange={(date) => {
                  setDataFim(date);
                  setGeneralData({
                    dataInit: dataInit
                      ? moment(dataInit)
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD ")
                      : null,
                    dataFim: moment(date)
                      .tz("America/Sao_Paulo")
                      .format("YYYY-MM-DD "),
                    eventID,
                  });
                }}
                placeholderText={t("components:datepicker.endDate")}
              />
            </div>
          </div>
          <div id="cards-dashboard" className="row dashboard-style">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">
                        Total de vendas
                      </h6>
                      <span className="h2 mb-0">
                        {noCentsCurrencyFormatter(fatMensal)}
                      </span>
                    </div>
                    <div className="col-auto">
                      <span className="h2 text-muted mb-0">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">
                        Ticket Médio
                      </h6>
                      <span className="h2 mb-0">
                        {noCentsCurrencyFormatter(ticketMedio)}
                      </span>
                    </div>
                    <div className="col-auto">
                      <span className="h2 text-muted mb-0">
                        <FontAwesomeIcon icon={faChartPie} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CARDS FINANCEIRO */}
          <div id="financial-dashboard" className="card mt-0">
            <div className="card-header container-register-user col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-header-title">
                    Detalhamento do TPV (Volume Total de Pagamentos)
                  </h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <h4 className="card-header-title">
                    Total: {noCentsCurrencyFormatter(fatMensal)}
                  </h4>
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                {detailsPayments.map((item) => (
                  <tr key={item.payment_type} className="financial-detail">
                    <td>
                      {item.payment_type !== "Cortesia" &&
                      item.payment_type !== "courtesy" ? (
                        <span>Valor transacionado {item.payment_type}</span>
                      ) : (
                        <span>Cortesias geradas</span>
                      )}
                    </td>
                    <td>{`${item.quantity}`}</td>
                    <td className="text-right">
                      {item.payment_type !== "Cortesia" &&
                      item.payment_type !== "courtesy" ? (
                        <span>{noCentsCurrencyFormatter(item.total)}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div id="financial-dashboard" className="card mt-0">
            <div className="card-header container-register-user col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-header-title">
                    Detalhamento da Ticketeira
                  </h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <h4 className="card-header-title">
                    Total: {noCentsCurrencyFormatter(taxa + jurosParc)}
                  </h4>
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr className="financial-detail">
                  <td>Taxas da plataforma</td>
                  <td className="text-right">
                    {noCentsCurrencyFormatter(taxa)}
                  </td>
                </tr>
                <tr className="financial-detail">
                  <td>Juros sobre parcelamento</td>
                  <td className="text-right">
                    {noCentsCurrencyFormatter(jurosParc)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="financial-dashboard" className="card mt-0">
            <div className="card-header container-register-user col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-header-title">
                    Detalhamento do Produtor
                  </h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <h4 className="card-header-title">
                    Total: {noCentsCurrencyFormatter(fatProdutor)}
                  </h4>
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr className="financial-detail">
                  <td>Valor do produtor</td>
                  <td className="text-right">
                    {noCentsCurrencyFormatter(fatProdutor)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
