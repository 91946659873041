import styled from "styled-components";

export const CardsContainer = styled.div`
  grid-area: table;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  @media (max-width: 768px) {
    display: block;
  }
  .carousel-inner {
    position: unset;
  }
  .carousel-item {
    position: unset;
  }
`;

export const ContentContainer = styled.div`
  padding-bottom: 2rem;
`;
