import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import useDebounce from "customerHooks/useDebounce";
import moment from "moment";
import Swal from "sweetalert2";

EventAutocomplte.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  onSelect: PropTypes.func,
};

export default function EventAutocomplte(props) {
  const { onChange, value, onSelect } = props;
  const [listEvent, setListEvent] = useState([]);
  const [drop, setDrop] = useState("");
  const [click, setClick] = useState("");
  const debounceValue = useDebounce(value, 500);

  const listEvents = useCallback((event, type) => {
    if (type === "click") {
      setDrop("");
      setListEvent([]);
    } else {
      const text = { text: event };
      api
        .post("/searchEvent", text)
        .then((res) => {
          setListEvent(res.data.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      dropdown();
    }
    // eslint-disable-next-line
  }, []);

  function dropdown() {
    if (drop === "show") {
      setDrop("");
    } else {
      setDrop("show");
    }
  }

  useEffect(() => {
    if (debounceValue) {
      listEvents(debounceValue, click);
    }

    if (value === "") {
      setDrop("");
      setClick("");
      setListEvent([]);
    }
    // eslint-disable-next-line
  }, [debounceValue, drop, value]);

  return (
    <>
      <div className="card-search">
        <FontAwesomeIcon icon={faSearch} className="icon-search" />
        <input
          type="text"
          className="input-custom"
          placeholder="Procure por um evento"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className={`drop-custom ${drop}`}>
        {listEvent.length > 0 && (
          <div className="dropdown-menu">
            {listEvent.map((event) => (
              // eslint-disable-next-line
              <div
                key={event.id}
                className="dropdown-item"
                onClick={() => {
                  setClick("click");
                  onChange(
                    `${event.name} ${moment(event.start_date).format(
                      "DD/MM/YYYY - HH:mm"
                    )}`
                  );
                  onSelect(event);
                }}
              >
                {`${event.name} ${moment(event.start_date).format(
                  "DD/MM/YYYY - HH:mm"
                )}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
