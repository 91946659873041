import NewPageHeader from "components/newPageHeader";
import React, { useEffect, useState } from "react";
import { Drawer } from "components/drawer";
import { useScreenWidth } from "customerHooks/useScreenWidth";
import marketplace_ticket_api from "services/marketplace_ticket";
import {
  ActionButton,
  ContentContainer,
  ModalContent,
  ModalTitle,
} from "./styles";
import { useTranslation } from "react-i18next";
import Subheader from "./components/subheader";
import TransfersPanel from "./components/transfers-panel";
import TransfersDetailDrawer from "./components/transfers-detail-drawer";
import TransfersConfiguration from "./components/transfers-configuration";
import IntegrationModal from "pages/integrationsPage/IntegrationModal";
import { ReactComponent as ExclamationSign } from "../../../assets/icons/exclamationSign.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/successIcon.svg";
import Toast from "./components/toast";

const NominalTransfers = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [ticketTransfers, setTicketTransfers] = useState([]);
  const [filter, setFilter] = useState("");
  const [pageInfo, setpageInfo] = useState({
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  });
  const [pageLimit, setPageLimit] = useState(10);
  const [eventTransferConfiguration, setEventTransferConfiguration] = useState({
    allowCourtesyTransfer: false,
    allowTicketTransfer: false,
    blockingDate: null,
    eventId: null,
    id: null,
    maximumTransfer: 0,
  });
  const [position, setPosition] = useState(0);
  const isMobile = useScreenWidth();
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const user = JSON.parse(localStorage.getItem("user"));

  const updateFilter = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    fetchTicketTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pageLimit]);

  useEffect(() => {
    fetchEventTransferConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEventTransferConfiguration = async () => {
    let apiUrl = `api/v1/event-transfer-settings/event/${event_id}`;
    try {
      marketplace_ticket_api.get(apiUrl).then((res) => {
        setEventTransferConfiguration(res.data);
      });
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
    }
  };

  const fetchTicketTransfers = async (page) => {
    let apiUrl = `api/v1/ticket-transfers/event/${event_id}`;
    try {
      marketplace_ticket_api
        .get(apiUrl, {
          params: {
            filter: filter,
            page: page,
            limit: pageLimit,
          },
        })
        .then((res) => {
          setTicketTransfers(res.data.items);
          setpageInfo(res.data.meta);
        });
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
    }
  };

  const fetchTransferDetails = async (transferId) => {
    const apiUrl = `api/v1/ticket-transfers/${transferId}`;
    try {
      const response = await marketplace_ticket_api.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
      return false;
    }
  };

  const deleteLatestTransfer = async (transferId) => {
    const apiUrl = `api/v1/ticket-transfers/${transferId}/canceled`;
    const data = {
      description: "Cancelamento da transferencia solicitado pelo cliente",
    };
    try {
      const response = await marketplace_ticket_api.put(apiUrl, data);
      if (response.status === 200) {
        fetchTicketTransfers();
        setModalOpen(false);
        setDrawerOpen(false);
        setToastOpen(true);
        return response.data;
      }
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
      return false;
    }
  };

  const handleNavigation = (page) => {
    fetchTicketTransfers(page);
  };

  const handleOpenDrawerClick = async (item) => {
    const details = await fetchTransferDetails(item.id);
    if (details) {
      setDrawerOpen(!drawerOpen);
      setDrawerContent({ details, ticketInfo: item });
    }
  };

  const handleTableItemTouch = (item) => {
    if (isMobile) handleOpenDrawerClick(item);
  };

  return (
    <ContentContainer>
      <NewPageHeader
        title={t("nominalTransfers.header.title")}
        subtitle={t("nominalTransfers.header.subtitle")}
      />
      {user?.is_admin === 1 && (
        <Subheader
          position={position}
          evento={event_id}
          changePosition={setPosition}
          t={t}
        />
      )}
      {position === 0 && (
        <TransfersPanel
          ticketTransfers={ticketTransfers}
          pageInfo={pageInfo}
          handleNavigation={handleNavigation}
          handleTableItemTouch={handleTableItemTouch}
          isMobile={isMobile}
          updateFilter={updateFilter}
          handleOpenDrawerClick={handleOpenDrawerClick}
          t={t}
          language={language}
          event={event_id}
          setPageLimit={setPageLimit}
        />
      )}
      {position === 1 && (
        <TransfersConfiguration
          eventTransferConfiguration={eventTransferConfiguration}
        />
      )}
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        width="none"
      >
        {drawerContent && (
          <TransfersDetailDrawer
            drawerContent={drawerContent}
            closeDrawer={() => setDrawerOpen(false)}
            setModalOpen={setModalOpen}
            isAdmin={user?.is_admin}
          />
        )}
      </Drawer>
      <IntegrationModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={
          <ModalTitle>
            <ExclamationSign /> {t("nominalTransfers.modal.title")}
          </ModalTitle>
        }
      >
        <ModalContent>
          <div>
            {t("nominalTransfers.modal.content")}
            <strong>
              {t("nominalTransfers.modal.contentStrong")}
              {drawerContent?.details?.ticketTransfer?.[0]?.sendName}{" "}
            </strong>
            {t("nominalTransfers.modal.contentTwo")}
          </div>
          <p>
            <ActionButton onClick={() => setModalOpen(false)}>
              <ArrowLeft strokeWidth="2px" />
              {t("nominalTransfers.modal.button.cancel")}
            </ActionButton>
            <ActionButton
              cancel
              onClick={() => deleteLatestTransfer(drawerContent.ticketInfo.id)}
            >
              {t("nominalTransfers.modal.button.confirm")}
            </ActionButton>
          </p>
        </ModalContent>
      </IntegrationModal>
      <Toast
        message={
          <>
            <SuccessIcon />
            {t("nominalTransfers.modal.toast")}
          </>
        }
        duration={3600}
        isOpen={toastOpen}
        setToastOpen={setToastOpen}
      />
    </ContentContainer>
  );
};

export default NominalTransfers;
