import PropTypes from "prop-types";
// React Imports
import React, { useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import Modal from "react-modal";
import styled from "styled-components";

import { breakPoints } from "services/breakPoints";
import { COLORS, SPACINGS } from "services/constants";

import CourtesyForm from "./cortesyForm";
import { ReactComponent as EditFile } from "../../../../../assets/icons/editFile.svg";
import { ReactComponent as FileArrowDown } from "../../../../../assets/icons/fileArrowDown.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/closeIcon.svg";
import ModalTypeButton from "components/modalTypeButton";
import Swal from "sweetalert2";
import "./styles.css";
import { CourtesyUploader } from "./courtesyUploader";
import New_api from "services/new_api";
import importLambda from "services/import-lambda";
import { useTranslation } from "react-i18next";
ModalCourtesyGenerator.propTypes = {
  event_id: PropTypes.string,
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
  }),
  refresh: PropTypes.func,
};

Modal.setAppElement("#root");

export default function ModalCourtesyGenerator({
  event_id,
  slug,
  toggleFunction: { isOpen, closeModal },
  refresh,
}) {
  // States
  const [typeModalCourtesy, setTypeModalCourtesy] = useState("courtesyForm");
  const [selectedFile, setSelectedFile] = useState();
  const [isDirty, setIsDirty] = useState(false);
  //

  const { t } = useTranslation("tickets", {
    keyPrefix: "courtesies.modalGenerator",
  });

  const { t: tg } = useTranslation("global");

  // Functions
  const changeTypeModal = (type) => {
    setTypeModalCourtesy(type);
  };

  const onSelectFile = (e) => {
    if (!e) {
      setIsDirty(false);
      setSelectedFile(undefined);
      return;
    }
    setIsDirty(true);
    setSelectedFile(e);
  };

  const handleDownloadTemplateClick = async () => {
    try {
      const response = await New_api.get(
        `events/${event_id}/courtesies/generate_csv`,
        {
          responseType: "blob",
        }
      );
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "template.csv");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(href);
      document.body.removeChild(link);
    } catch {}
  };

  const onClose = () => {
    closeModal(typeModalCourtesy === "response" ? true : false);
    setTypeModalCourtesy("courtesyForm");
  };

  const handleDownloadFileClick = (e) => {
    try {
      e.stopPropagation();
      if (selectedFile) {
        const href = URL.createObjectURL(selectedFile);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", selectedFile.name);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
        document.body.removeChild(link);
      }
    } catch {}
  };

  const postCourtesiesLotData = (key) => {
    try {
      const body = {
        key,
        companyName: process.env.REACT_APP_WHITELABEL,
        eventId: event_id,
      };
      importLambda.post("start-ecs", body);
    } catch {}
  };

  const putCourtesies = async (data) => {
    fetch(data.uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "text/csv",
      },
      body: selectedFile,
    })
      .then((response) => {
        if (response.ok) {
          postCourtesiesLotData(data.key);
        }
      })
      .catch(() => {});
  };

  const handleSubmit = () => {
    Swal.fire({
      showCancelButton: true,
      title: t("alert.submit.title"),
      icon: "warning",
      text: t("alert.submit.text"),
      confirmButtonColor: "#0050c3",
      confirmButtonText: `<span>${t("alert.submit.confirmButton")}</span>`,
      reverseButtons: true,
      cancelButton: tg("back"),
      customClass: {
        confirmButton: "courtesy-button-contained",
        cancelButton: "courtesy-button-outline",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        importLambda(
          `get-upload-url?fileName=${selectedFile.name}&companyName=${process.env.REACT_APP_WHITELABEL}`
        )
          .then(async (response) => {
            Swal.fire({
              title: t("alert.ongoing.title"),
              icon: "info",
              confirmButtonColor: "#0050c3",
              text: t("alert.ongoing.text"),
              confirmButtonText: t("confirmButtonText"),
            });
            putCourtesies(response.data.data);
            onClose();
            setSelectedFile(undefined);
          })
          .catch((error) => {
            Swal.fire({
              title: t("ops"),
              icon: "error",
              text: t("errors.serverError"),
              confirmButtonText: t("confirmButtonText"),
            });
          });
      }
    });
  };

  const navigationAlertText = (typeModal) => {
    if (typeModal === "courtesyUpload") {
      return t("alert.isDirty.textCourtesyForm");
    } else {
      return t("alert.isDirty.textCourtesyUpload");
    }
  };

  const handleRenderModal = (typeModal) => {
    if (isDirty) {
      Swal.fire({
        title: t("alert.isDirty.title"),
        icon: "info",
        text: navigationAlertText(typeModal),
        showCancelButton: true,
        confirmButtonText: t("alert.isDirty.confirmButton"),
        confirmButtonColor: "#0050c3",
        reverseButtons: true,
        cancelButton: t("alert.isDirty.cancelButton"),
        customClass: {
          cancelButton: "courtesy-button-outline",
          confirmButton: "courtesy-button-outline",
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            setTypeModalCourtesy(typeModal);
          }
        })
        .finally(() => {
          if (selectedFile) {
            setSelectedFile(undefined);
          }
        });
    } else {
      setTypeModalCourtesy(typeModal);
    }
  };

  const renderModalContent = (typeModalCourtesy) => {
    switch (typeModalCourtesy) {
      case "courtesyUpload":
        return (
          <CourtesyUploader
            handleDownloadTemplateClick={handleDownloadTemplateClick}
            onSelectFile={onSelectFile}
            selectedFile={selectedFile}
            handleDownloadFileClick={handleDownloadFileClick}
            handleSubmit={handleSubmit}
          />
        );
      case "load":
        return (
          <LoadingContainer>
            <LoadingIcon color={COLORS.darkBlue} size={150} />
          </LoadingContainer>
        );
      case "courtesyForm":
        return (
          <CourtesyForm
            onClose={onClose}
            event_id={event_id}
            slug={slug}
            changeTypeModal={changeTypeModal}
            setIsDirty={setIsDirty}
            refresh={refresh}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onClose}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>{t("title")}</HeaderText>
          <CloseButton onClick={onClose}>
            <CloseIcon size={25} />
          </CloseButton>
        </HeaderContainer>
        <ModalTypeButtonContainer>
          <ModalTypeButton
            handleClick={() => handleRenderModal("courtesyForm")}
            title={t("modalTypeButton.insert")}
            modalType="courtesyForm"
            typeModalCourtesy={typeModalCourtesy}
          >
            <EditFile />
          </ModalTypeButton>
          <ModalTypeButton
            handleClick={() => handleRenderModal("courtesyUpload")}
            title={t("modalTypeButton.import")}
            modalType="courtesyUpload"
            typeModalCourtesy={typeModalCourtesy}
          >
            <FileArrowDown />
          </ModalTypeButton>
        </ModalTypeButtonContainer>
        {renderModalContent(typeModalCourtesy)}
      </ModalContainer>
    </ModalComponent>
  );
}

//////////////// Modal styles ///////////////////
const ModalTypeButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  padding-top: 1rem;
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

const ModalComponent = styled(Modal)`
  width: 100%;
  overflow-x: hidden;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: visible;
`;

const HeaderContainer = styled.div`
  padding: ${SPACINGS.space4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 1px ${COLORS.neutralColorLightL2} solid;
`;

const HeaderText = styled.span`
  padding-left: ${SPACINGS.space2};
  color: ${COLORS.neutralColorDarkD4};
  text-align: center;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

const LoadingContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
