import Loading from "components/loading";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import Swal from "sweetalert2";
import { formatCurrency } from "utils";
import { capitalizeEachFirstWord } from "utils/str-format";
import csswelcome from "../../../cssdinamico";
import "./styles.css";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import NewPageHeader from "components/newPageHeader";
import NewSelect from "components/newSelect";
import { NoSalesYet } from "./components/NoSalesYet";
import { TotalSalesOrPaidSales } from "./components/TotalSalesOrPaidSales";
import { FreeTicketsReports } from "./components/FreeTicketsReport";

const defaultPaymentMethods = {
  "pt-BR": [
    { name: "Boleto", qty: 0, value: 0 },
    { name: "Crédito", qty: 0, value: 0 },
    { name: "PIX", qty: 0, value: 0 },
  ],
  en: [
    { name: "Credit", qty: 0, value: 0 },
    { name: "Debit", qty: 0, value: 0 },
  ],
  es: [
    { name: "Crédito", qty: 0, value: 0 },
    { name: "Débito", qty: 0, value: 0 },
  ],
};

export default function Eventodetalhes() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [relatorio, setRelatorio] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation(["tickets", "global", "backend"]);
  const dashboardFilters = [
    { value: undefined, label: t("global:allTickets") },
    { value: "paid", label: t("global:paidTickets") },
    { value: "free", label: t("global:freeTickets") },
  ];
  const [filter, setFilter] = useState(dashboardFilters[0]);
  useEffect(() => {
    csswelcome();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, filter]);
  const { currency } = useCurrencyEventContext();

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  const load = async () => {
    setIsLoading(true);
    api
      .get(`event/dashboard/${event_id}`, {
        params: { type_ticket: filter.value },
      })
      .then((res) => {
        let relatorio = res.data;
        if (!relatorio.origem_receitas) {
          relatorio.origem_receitas = [
            {
              name: t("global:onlineSales"),
              value: 0,
              types: [
                {
                  name: capitalizeEachFirstWord(t("global:online")),
                  qty: 0,
                  value: 0,
                  payments: defaultPaymentMethods[i18n.language],
                },
              ],
            },
          ];
        } else {
          relatorio.origem_receitas = relatorio.origem_receitas.map(
            (origem) => {
              return {
                name: capitalizeEachFirstWord(
                  t(origem.name, { ns: "backend" })
                ),
                value: origem.value,
                types: origem.types.map((type) => {
                  return {
                    name: capitalizeEachFirstWord(
                      t(type.name, { ns: "backend" })
                    ),
                    qty: type.qty,
                    value: type.value,
                    payments: type.payments.map((payment) => {
                      return {
                        name: capitalizeEachFirstWord(
                          t(payment.name, { ns: "backend" })
                        ),
                        qty: payment.qty,
                        value: payment.value,
                      };
                    }),
                  };
                }),
              };
            }
          );
        }
        setRelatorio(relatorio);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading title="aguarde..." />
      ) : (
        <Fragment>
          <NewPageHeader
            title={t("tickets:dashboard.title")}
            subtitle={t("tickets:dashboard.subtitle")}
          />
          <div
            id="page-dashboard-ticket"
            className="container-fluid page-content"
          >
            <div id="card" className="col-12 mt-3">
              <div className="row">
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="card-report card-one">
                    <p className="card-report-title">
                      {t("tickets:dashboard.cards.totalSold.title")}
                    </p>
                    <span
                      className="card-report-value"
                      data-tip={t("tickets:dashboard.cards.totalSold.tooltip")}
                    >
                      {formatCurrency(relatorio.totalSales || 0, {
                        isCents: false,
                        currency,
                      })}
                    </span>
                    <div>
                      <p className="card-report-subtotal">
                        {`${formatCurrency(relatorio.vendas_totais || 0, {
                          isCents: false,
                          currency,
                        })} - `}
                        {t("tickets:dashboard.cards.totalSold.producerReceipt")}
                      </p>
                      <p className="card-report-subtotal">
                        {`${formatCurrency(relatorio.vendas_dinheiro_totais, {
                          isCents: false,
                          currency,
                        })} - `}
                        {t("tickets:dashboard.cards.totalSold.moneyReceipt")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="card-report card-two">
                    <p className="card-report-title">
                      {t("tickets:dashboard.cards.average.title")}
                    </p>
                    <p
                      className="card-report-value"
                      data-tip={t("tickets:dashboard.cards.average.tooltip")}
                    >
                      {formatCurrency(relatorio.ticket_medio || 0, {
                        isCents: false,
                        currency,
                      })}
                    </p>
                    <div>
                      <p className="card-report-subtitle">
                        {t("tickets:dashboard.cards.average.buyingAverage")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 mb-3">
                  <div className="card-report card-three">
                    <p className="card-report-title">
                      {t("tickets:dashboard.cards.emittedTickets.title")}
                    </p>
                    <p
                      className="card-report-value"
                      data-tip={t(
                        "tickets:dashboard.cards.emittedTickets.tooltip"
                      )}
                    >
                      {relatorio.ingressos_vendidos +
                        relatorio.cortesias_emitidas || 0}
                    </p>
                    <div>
                      <p className="card-report-subtotal">
                        {t("tickets:dashboard.cards.emittedTickets.sold", {
                          count: relatorio.ingressos_vendidos || 0,
                        })}{" "}
                        +{" "}
                        {t("tickets:dashboard.cards.emittedTickets.courtesy", {
                          count: relatorio.cortesias_emitidas || 0,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(relatorio.vendas_ingresso || relatorio.origem_receitas) && (
              <div
                className="col-12 md-layout md-alignment-center-space-around"
                id="bottom-cards"
              >
                <div className="col-12 col-md-3 d-flex flex-row p-0 mt-3 mb-2">
                  <NewSelect
                    isSearchable={false}
                    options={dashboardFilters}
                    value={filter}
                    onChange={handleFilter}
                  />
                </div>
                {relatorio.vendas_ingresso?.sectors?.length === 0 ||
                !relatorio.vendas_ingresso?.sectors ||
                !relatorio.origem_receitas ||
                relatorio.origem_receitas.payments?.length === 0 ? (
                  <NoSalesYet />
                ) : filter.value === "free" ? (
                  <FreeTicketsReports
                    sectors={relatorio.vendas_ingresso?.sectors}
                    originSales={relatorio.origem_receitas || []}
                  />
                ) : (
                  <TotalSalesOrPaidSales
                    sectors={relatorio.vendas_ingresso?.sectors}
                    totalTicketSales={relatorio.totalTicketSales}
                    absorbedTaxes={relatorio.absorbed_taxes}
                    originSales={relatorio.origem_receitas || []}
                    filter={filter}
                  />
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
