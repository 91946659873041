import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
import ButtonContained from "components/buttonContained";
import Loading from "components/loading";
import NewInput from "components/newInput";
import NewSelect from "components/newSelect";
import CreateTransferModal from "components/transfer-modals/create-transfer-modal";
import TransferDetailsModal from "components/transfer-modals/transfer-details-modal";
import { useLanguageSettings } from "hooks/language-settings";
import { destinyKeys, transferDestiny } from "services/constants";
import New_api from "services/new-api";
import api from "services/api";
import { formatCurrency } from "utils";

import { ReactComponent as Download2 } from "../../../assets/icons/download2.svg";
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning-yellow.svg";

import { useCurrencyEventContext } from "contexts/currency-event-context";
import "./styles.css";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
import { BorderoCard } from "components/bordero-card";

// eslint-disable-next-line
let container;

const statusBuilder = {
  SOLICITADO: {
    class: "badge badge-pill badge-light",
    name: "SOLICITADO",
  },
  "EM ANÁLISE": {
    class: "badge badge-pill badge-warning",
    name: "EM ANÁLISE",
  },
  AGUARDANDO: {
    class: "badge badge-pill badge-success",
    name: "AGUARDANDO",
  },
  PAGO: {
    class: "badge badge-pill badge-success",
    name: "PAGO",
  },
  CANCELADO: {
    class: "badge badge-pill badge-light",
    name: "CANCELADO",
  },
  RECUSADO: {
    class: "badge badge-pill badge-danger",
    name: "RECUSADO",
  },
};

const statusOptions = {
  "EM ANÁLISE": [
    statusBuilder.SOLICITADO.name,
    statusBuilder.RECUSADO.name,
    statusBuilder.AGUARDANDO.name,
  ],
  AGUARDANDO: [
    statusBuilder.PAGO.name,
    statusBuilder.CANCELADO.name,
    statusBuilder.RECUSADO.name,
    statusBuilder.SOLICITADO.name,
  ],
};

export default function FinanceiroTransferencias() {
  const user = JSON.parse(localStorage.getItem("user"));
  const urlParams = new URLSearchParams(window.location.search);
  const activateTransferLock = process.env.REACT_APP_TRANSFER_LOCK === "1";
  const initialPerPage = 25;
  const { currency: backofficeCurrency, symbol: backofficeSymbol } =
    useLanguageSettings();
  const { currency: eventCurrency, currencySymbol: eventSymbol } =
    useCurrencyEventContext();

  const [eventId, setEventId] = useState();
  const [transferencias, setTransferencias] = useState([]);
  // eslint-disable-next-line
  const event_id = urlParams.get("event_id");
  const { t } = useTranslation("transfers");
  const [summary, setSummary] = useState({
    available: 0,
    pending: 0,
    transfered: 0,
  });
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [filteredByStatus, setFilteredByStatus] = useState([]);
  const [filteredByDestiny, setFilteredByDestiny] = useState();
  const [filteredByRecipientOrEvent, setFilteredByRecipientOrEvent] =
    useState();
  const [reload, setReload] = useState(false);
  const [perPage, setPerPage] = useState(initialPerPage);

  //New States
  const [eventName, setEventName] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [isEventContext, setEventContext] = useState(false);
  const [originalStatusList, setOriginalStatusList] = useState([]);
  const currency = isEventContext ? eventCurrency : backofficeCurrency;
  const symbol = isEventContext ? eventSymbol : backofficeSymbol;

  const verifyTransferEligibility = async (
    end_date,
    event_status,
    organization_id
  ) => {
    const today = new Date();
    const eventEndDate = new Date(end_date);
    const isEventFinished =
      event_status !== "ativo" && event_status !== "adiado";

    if (user.is_admin) {
      return;
    }

    if (isEventFinished) {
      setIsButtonDisabled(true);
      return;
    }

    if (today >= eventEndDate) {
      setIsButtonDisabled(true);
      return;
    }

    const {
      data: {
        organization: { producer },
      },
    } = await api.get(`/organization/show/${organization_id}`);

    if (producer.user_id !== user.id) {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    New_api.get("/transfer_transactions_statuses").then(({ data }) => {
      const status = data.map(({ name, id }) => ({
        value: { id, name },
        label: statusBuilder[name.toUpperCase()]?.name,
        id,
      }));
      setOriginalStatusList(status);
      setStatusList(status);
    });

    if (event_id) {
      setEventContext(true);
      setEventId(event_id);
      carregaTransferencias(event_id, "initial", 1, perPage);

      New_api.get(`/events/${event_id}`, {
        params: {
          fields: ["name", "end_date", "event_status", "organization_id"],
        },
      })
        .then(
          async ({
            data: { name, end_date, event_status, organization_id },
          }) => {
            setEventName(name);
            if (activateTransferLock) {
              await verifyTransferEligibility(
                end_date,
                event_status,
                organization_id
              );
            }
          }
        )
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      carregaTransferencias(null);
      setEventContext(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [event_id]);

  useEffect(() => {
    // eslint-disable-next-line
    if (event_id) {
      carregaTransferencias(event_id, "initial", 1, perPage);
    } else {
      carregaTransferencias(null);
    }

    setReload(false);
    // eslint-disable-next-line
  }, [reload]);

  const carregaTransferencias = (id, typePage, numberPage, perPage) => {
    let changeNumberPage = numberPage;
    let pageConsult = {
      page: numberPage,
      per_page: perPage,
      by_search: filteredByRecipientOrEvent,
      by_status: filteredByStatus,
      by_destiny: filteredByDestiny,
    };

    switch (typePage) {
      case "Initial":
        pageConsult = {
          page: 1,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        } else {
          changeNumberPage++;
          pageConsult = {
            page: changeNumberPage,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        }
        break;
      case "current":
        pageConsult = {
          page: numberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;

      case "left":
        changeNumberPage--;
        pageConsult = {
          page: changeNumberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;
      default:
        pageConsult = {
          page: 1,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
    }

    if ((id || eventId) && user.is_admin) {
      pageConsult["by_event"] = id || eventId;
    }

    const route = user.is_admin
      ? "transfers"
      : `/transfers?by_event=${eventId || id}`;

    const listPromise = New_api.get(route, { params: pageConsult }).then(
      (res) => {
        setTransferencias(res.data.data);
        setAtualizaPaginaAtual(res.data.meta.current_page);
        setAtualizaUltimaPagina(res.data.meta.last_page);
        setAtualizaQuantPesquisa(res.data.meta.total);
      }
    );

    Promise.all([listPromise, fetchSummary()])
      .then(() => {
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  function fetchSummary() {
    let summaryPromise = Promise.resolve();

    if (!!event_id) {
      summaryPromise = New_api.get(`transfers/summary/${event_id}`).then(
        (res) => setSummary(res.data)
      );
    }

    return summaryPromise;
  }

  function openModalDetailsTransfer(item) {
    const { status } = item;
    statusOptionsListBuilder(status.toUpperCase());
    setTransactionStatus({
      label: statusBuilder[status.toUpperCase()].name,
      value: originalStatusList.find(
        (item) => item.label === status.toUpperCase()
      ),
    });

    setSelectedTransfer(item);
    setShowDetails(true);
  }

  const statusOptionsListBuilder = (status) => {
    const statusItems = [
      statusBuilder["AGUARDANDO"].name,
      statusBuilder["EM ANÁLISE"].name,
    ].includes(status)
      ? statusOptions[status]
      : [statusBuilder.SOLICITADO.name];

    if (status.toUpperCase() !== statusBuilder.SOLICITADO.name) {
      setStatusList(
        originalStatusList.filter((status) =>
          statusItems.includes(status.label)
        )
      );
    } else {
      setStatusList(originalStatusList);
    }
  };

  const handleUpdatePerPage = (event) => {
    const newPerPage = event.target.value;

    setPerPage(newPerPage);
    carregaTransferencias(eventId, "initial", 1, perPage);
  };

  const handleApproval = (transfer, error, transactionInfos) => {
    if (error) {
      setTransactionStatus(
        originalStatusList.find(({ name }) => name === "AGUARDANDO PAGAMENTO")
      );
      Swal.fire({
        title: "Erro ao aprovar transferencia, tente novamente",
        icon: "warning",
        text: error.response.data.message,
        confirmButtonText: "Ok",
      });
    } else {
      setShowDetails(false);
      Swal.fire({
        title: "Transferencia aprovada com sucesso",
        icon: "success",
        confirmButtonText: "Ok",
      });

      const index = transferencias.findIndex((item) => item.id === transfer.id);

      transferencias[index] = transfer;
      setTransferencias([...transferencias]);
    }
  };

  const handleRefuse = (transfer, error) => {
    if (error) {
      setTransactionStatus(
        originalStatusList.find(({ name }) => name === "EM ANÁLISE")
      );
      Swal.fire({
        title: "Erro ao recusar a transferência, tente novamente",
        icon: "warning",
        text: error.response.data.message,
        confirmButtonText: "Ok",
      });
    } else {
      setShowDetails(false);
      Swal.fire({
        title: "Transferência recusada com sucesso",
        icon: "success",
        confirmButtonText: "Ok",
      });

      const index = transferencias.findIndex((item) => item.id === transfer.id);

      transferencias[index] = transfer;
      setTransferencias([...transferencias]);
    }
  };

  const handleCreation = (transfer, error) => {
    if (error) {
      Swal.fire({
        title: "Erro ao registrar transferência.",
        icon: "error",
        text:
          error.response.data.message || "Confira os dados e tente novamente",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Boa! Saque solicitado",
        icon: "success",
        text: "O seu pedido será analisado por nossa equipe e caso seja aprovado será realizado em até 3 dias úteis.",
        confirmButtonText: "Ok",
      });

      fetchSummary()
        .then(() => {})
        .catch(() => window.reload());

      setTransferencias([transfer, ...transferencias]);
    }
  };

  const handleTransferModalClose = () => {
    setTransactionStatus(null);
    setStatusList(originalStatusList);
    setShowDetails((prevState) => !prevState);
  };

  const handleCreationStatusLog = (transferInfos) => {
    New_api.post("/transfer_transactions_logs", transferInfos)
      .catch((error) => {
        handleTransferModalClose();
        console.error(error);
      })
      .finally(() => setReload(true));
  };

  const handleTransactionStatus = (status, transferInfos) => {
    setTransactionStatus(status);

    if (!["PAGO", "RECUSADO"].includes(status.label)) {
      handleCreationStatusLog(transferInfos);
      setTransferencias((prevState) =>
        prevState.map((transfer) => ({
          ...transfer,
          status:
            transferInfos.transfer_transaction_id === transfer.id
              ? status.label
              : transfer.status,
        }))
      );
    }
  };

  const exportInXlxs = async () => {
    const params = {
      by_search: filteredByRecipientOrEvent,
      by_status: filteredByStatus,
      by_destiny: filteredByDestiny,
    };
    const response = await New_api.get(
      `/transfers/xlsx${eventId ? `?event_id=${eventId}` : ""}`,
      {
        responseType: "blob",
        params,
      }
    );

    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "transferências.xlsx");
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(href);
    document.body.removeChild(link);
  };

  const transferDestinySelection = Object.keys(transferDestiny).map((key) => ({
    value: key,
    label: transferDestiny[key],
  }));

  const filterStatus = (e) => {
    const status = e.map((item) => item.value.name);
    setFilteredByStatus(status);
    setReload(true);
  };

  const filterDestiny = (e) => {
    if (e !== null) {
      setFilteredByDestiny(e.value);
    } else {
      setFilteredByDestiny("");
    }
    setReload(true);
  };

  const debouncedFilterRecipientOrEvent = debounce(async (value) => {
    setFilteredByRecipientOrEvent(value);
    setReload(true);
  }, 800);

  const filterRecipientOrEvent = (e) => {
    debouncedFilterRecipientOrEvent(e.target.value.trim());
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading title="aguarde..." />
      ) : (
        <>
          <ToastContainer
            ref={(reff) => {
              container = reff;
            }}
            className="toast-top-right"
          />

          <NewPageHeader
            title={t("transfers:header.title")}
            subtitle={t("transfers:header.subtitle")}
            children={
              <div className="expenses-button">
                <ButtonContained
                  disabled={isButtonDisabled}
                  isTransfersPage={true}
                  className={
                    isButtonDisabled
                      ? "disabled-contained-2"
                      : "primary-contained"
                  }
                  onClick={() => setShowCreate(true)}
                  content={
                    <Fragment>
                      {user.is_admin
                        ? t("transfers:header.buttonText1")
                        : t("transfers:header.buttonText2")}
                    </Fragment>
                  }
                />
              </div>
            }
          />

          {isButtonDisabled && (
            <div className="text-left">
              <Alert className="warning-box m-4">
                <Alert.Heading className="mr-3">
                  <Warning width={24} height={24} />
                </Alert.Heading>
                <div>
                  {t("transfers:warningBox.textPart1")}{" "}
                  <Alert.Link
                    href="https://wa.me/5511977401869"
                    className="warning-box-link"
                  >
                    {t("transfers:warningBox.textPart2")}
                  </Alert.Link>{" "}
                  {t("transfers:warningBox.textPart3")}
                </div>
              </Alert>
            </div>
          )}

          <div id="transfer-transactions" className="container-fluid">
            {!!event_id && (
              <div id="card" className="col-12 mt-4">
                <div className="row">
                  <div className="col-lg-6 col-xl-4 mb-3">
                    <BorderoCard
                      amount={formatCurrency(summary.available, {
                        currency,
                        isCents: true,
                      })}
                      description={t("transfers:cards.balance")}
                      tooltip={t("transfers:cards.balanceTooltip")}
                      isTransferPage={true}
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 mb-3">
                    <BorderoCard
                      amount={formatCurrency(summary.pending, {
                        currency,
                        isCents: true,
                      })}
                      description={t("transfers:cards.pendingTransfers")}
                      isTransferPage={true}
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 mb-3">
                    <BorderoCard
                      amount={formatCurrency(summary.transfered, {
                        currency,
                        isCents: true,
                      })}
                      description={t("transfers:cards.completedTransfers")}
                      isTransferPage={true}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-12">
              <div className="row mt-2">
                <div className="col-12">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="col-md-4 col-12 mb-2 pr-0 pl-0">
                      <NewInput
                        icon={<Search width={20} height={20} />}
                        className="icon-new-input"
                        type="text"
                        onChange={(e) => filterRecipientOrEvent(e)}
                        placeholder={`${t(
                          "transfers:inputs.searchPlaceholder"
                        )} ${
                          !event_id
                            ? t("transfers:inputs.searchPlaceholder2")
                            : ""
                        }`}
                      />
                    </div>
                    <div className="col-md-5 col-sm-10 mb-2 d-flex flex-wrap w-100 p-0">
                      <div className="col-md-5 mb-md-0 mb-2 pr-0 pl-0 pr-md-2 pl-md-2">
                        <NewSelect
                          className="w-100 font-weight-bold"
                          classNamePrefix="select"
                          placeholder={t("transfers:inputs.selectPlaceholder")}
                          options={originalStatusList}
                          onChange={filterStatus}
                          isMulti
                        />
                      </div>
                      <div className="col-md-5 ml-0 pr-0 pl-0">
                        <NewSelect
                          className="w-100 font-weight-bold"
                          classNamePrefix="select"
                          placeholder={t("transfers:inputs.selectPlaceholder2")}
                          isClearable={true}
                          options={transferDestinySelection}
                          onChange={filterDestiny}
                        />
                      </div>
                    </div>
                    <div className="d-flex col-md-3 col-12 mb-2 pr-0 pl-0 pl-md-3">
                      <ButtonContained
                        className="secondary-outlined select-transfers"
                        onClick={() => exportInXlxs()}
                        content={
                          <Fragment>
                            <Download2 stroke="#0050C3" />
                            {t("transfers:inputs.exportPlaceholder")}
                          </Fragment>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="table-responsive">
                  <table
                    id="table-totalsales"
                    className="table table-sm card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        {!eventId && (
                          <Fragment>
                            <th scope="col">
                              {t("transfers:inputs.eventColumn")}
                            </th>
                          </Fragment>
                        )}
                        <th scope="col" className="text-left">
                          {t("transfers:table.userColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.orderColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.dateColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.valueColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.destinyColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.statusColumn")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("transfers:table.detailsColumn")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transferencias.map((transfer) => (
                        <tr key={transfer.id}>
                          {!eventId && (
                            <Fragment>
                              <td className="details-code-user">
                                {transfer.event ? transfer.event.name : ""}
                              </td>
                            </Fragment>
                          )}
                          <td className="text-left">{transfer.name}</td>
                          <td className="text-center">
                            {`${transfer.requester?.first_name} ${transfer.requester?.last_name}`}{" "}
                            <br />
                            {transfer.requester?.email}
                          </td>
                          <td className="text-center">
                            {moment(transfer.created_at).format(
                              "DD/MM/yyyy-HH:mm:ss"
                            )}
                          </td>
                          <td className="text-center">
                            {formatCurrency(transfer.amount, {
                              currency,
                              isCents: true,
                            })}
                          </td>
                          <td className="text-center">
                            {destinyKeys[transfer.destiny] ||
                              t("transfers:table.notInformed")}
                          </td>
                          <td className="text-center">
                            <span
                              className={
                                statusBuilder[transfer.status.toUpperCase()]
                                  ?.class
                              }
                            >
                              {
                                statusBuilder[transfer.status.toUpperCase()]
                                  ?.name
                              }
                            </span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn-table"
                                onClick={() =>
                                  openModalDetailsTransfer(transfer)
                                }
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-12">
                  <div className="row d-flex justify-content-between datatable-footer">
                    <div className="col-md-6 d-flex align-items-center">
                      <span className="mr-2 ">
                        {t("transfers:pagination.resultsByPage")}
                      </span>
                      <div>
                        <select
                          name="SelecaoPorPagina"
                          onChange={handleUpdatePerPage}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                      {perPage * atualizaPaginaAtual >=
                      atualizaQuantPesquisa ? (
                        <span>
                          {`${t(
                            "transfers:pagination.exhibit"
                          )} ${atualizaQuantPesquisa} ${t(
                            "transfers:pagination.of"
                          )} ${atualizaQuantPesquisa}`}
                        </span>
                      ) : (
                        <span>
                          {`${t("transfers:pagination.exhibit")} ${
                            perPage * atualizaPaginaAtual
                          } ${t(
                            "transfers:pagination.of"
                          )} ${atualizaQuantPesquisa}`}
                        </span>
                      )}

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(eventId, "initial", 1, perPage);
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "left",
                            atualizaPaginaAtual,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <span>
                        {`${t(
                          "transfers:pagination.page"
                        )} ${atualizaPaginaAtual} ${t(
                          "transfers:pagination.of"
                        )} ${atualizaUltimaPagina}`}
                      </span>

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "right",
                            atualizaPaginaAtual,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "lastPage",
                            atualizaUltimaPagina,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isLoading && (
            <CreateTransferModal
              show={showCreate}
              currency={currency}
              title={
                user.is_admin
                  ? t("transfers:header.buttonText1")
                  : t("transfers:header.buttonText2")
              }
              statusList={statusList}
              maxValue={event_id ? summary.available / 100 : null}
              eventId={Number(event_id)}
              eventName={user.is_admin ? null : eventName}
              onError={(error) => handleCreation(null, error)}
              onSuccess={(transfer) => handleCreation(transfer)}
              onClose={() => setShowCreate(false)}
            />
          )}

          <TransferDetailsModal
            show={showDetails}
            transactionStatus={transactionStatus}
            statusList={statusList}
            currency={currency}
            currencySymbol={symbol}
            transfer={selectedTransfer}
            setSelectedTransfer={setSelectedTransfer}
            onApprove={handleApproval}
            onRefuse={handleRefuse}
            handleTransactionStatus={handleTransactionStatus}
            onClose={handleTransferModalClose}
            statusOptionsListBuilder={statusOptionsListBuilder}
          />
        </>
      )}
    </Fragment>
  );
}
