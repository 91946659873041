import { format, sub } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { ReactComponent as TransferIcon } from "../../../assets/icons/TransferIcon.svg";

// import { Summary } from "./components/summary";
import ButtonContained from "components/buttonContained";
import { CollapsibleContainer } from "components/collapsible-container";
import { DatePicker } from "components/datepicker";
import Loading from "components/loading";
import NewPageHeader from "components/newPageHeader";
import useQuery from "customerHooks/useQuery";
import financialApi from "services/financial-api";

import { ReactComponent as Add } from "../../../assets/icons/Add.svg";
import { ReactComponent as AddCircle } from "../../../assets/icons/AddCircleWhite.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as DeductionIcon } from "../../../assets/icons/deductions-icon.svg";
import { ReactComponent as Download2 } from "../../../assets/icons/download2.svg";
import { ReactComponent as TicketIcon } from "../../../assets/icons/ticket-icon.svg";
// import { ContainerContent } from "utils/currency";
import { ContainerContent } from "./components/container-content";
import { useBorderoHelpers } from "./helper-methods";
import { Container, ContainersWrapper, FiltersContainer, Main } from "./styles";

import "./styles.css";
// import { Summary } from "./components/summary";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { ToastContainer } from "react-toastr";
import New_api from "services/new-api";
import DeductionsModal from "./components/deductions-modal";

let container;

export const Bordero = () => {
  const query = useQuery();
  const eventId = query.get("event_id");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expenseInfo, setExpenseInfo] = useState();
  const [transfers, setTransfers] = useState({});
  const [deductions, setDeductions] = useState({});
  const [sales, setSales] = useState({});
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    eventStartDate: null,
  });

  const { currency, eventDetails, isLoading } = useCurrencyEventContext();

  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");

  const handleEdit = (expense) => {
    setExpenseInfo(expense);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t("financialStatement.modal.title"),
      text: t("financialStatement.modal.content"),
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: t("financialStatement.modal.cancelButton"),
      confirmButtonText: t("financialStatement.modal.confirmButton"),
      confirmButtonColor: "#0050C3",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await New_api.delete(
          `/event/bordero/deductions/${id}?event_id=${eventId}`
        )
          .then(() => {
            setReload(true);
            container.success(t("financialStatement.expenseDeleted"));
          })
          .catch(() => {
            container.error(t("financialStatement.expenseDeletedError"));
          })
          .finally(() => {
            setReload(false);
          });
      }
    });
  };

  const { deductionsHelpers, salesHelper, transferHelpers } = useBorderoHelpers(
    { currency, handleDelete, handleEdit }
  );

  const loadData = async (startDate, endDate) => {
    const dateParams =
      startDate && endDate
        ? `&startDate=${format(startDate, "yyyy-MM-dd")}&endDate=${format(
            endDate,
            "yyyy-MM-dd"
          )}`
        : "";
    try {
      setLoading(true);
      const [deductions, transfers, sales] = await Promise.all([
        financialApi
          .get(`/bordero/deductions?eventId=${eventId}${dateParams}`)
          .then((res) => res.data),
        financialApi
          .get(`/bordero/transfers?eventId=${eventId}${dateParams}`)
          .then((res) => res.data),
        financialApi
          .get(`/bordero/sales?eventId=${eventId}${dateParams}`)
          .then((res) => res.data),
      ]);
      deductions.headerData = deductionsHelpers.mapHeader(
        deductions.headerData
      );
      deductions.tables = deductionsHelpers.mapTables(deductions);
      transfers.headersData = transferHelpers.mapHeader(transfers.header);
      transfers.tables = transferHelpers.mapTables(
        transfers.transfers,
        transfers.total
      );
      sales.headersData = salesHelper.mapHeader(sales.totals);
      sales.tables = salesHelper.mapTables(sales);
      setSales(sales);
      setTransfers(transfers);
      setDeductions(deductions);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const exportInXlxs = async () => {
    setIsDownloading(true);
    try {
      const dateParams =
        dates.startDate && dates.endDate
          ? `&startDate=${format(
              dates.startDate,
              "yyyy-MM-dd"
            )}&endDate=${format(dates.endDate, "yyyy-MM-dd")}`
          : "";
      const response = await financialApi.get(
        `/bordero/xlsx?eventId=${eventId}&language=${language}${dateParams}`,
        { responseType: "blob" }
      );

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${t("financialStatement.fileName")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(href);
      document.body.removeChild(link);
    } catch (error) {
      Swal.fire({
        title: t("global:errors.ops"),
        icon: "error",
        text: t("global:errors.internalServerError"),
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setExpenseInfo();
  };

  const loadDefaultDates = async () => {
    const { created_at } = eventDetails;
    setDates({
      endDate: new Date(),
      eventStartDate: new Date(created_at),
      startDate: new Date(created_at),
    });
  };
  const maxStartDate = sub(dates.endDate, { days: 1 });
  const initPage = async () => {
    await Promise.all([loadData(), loadDefaultDates()]);
  };

  useEffect(() => {
    loadData();
    initPage();
    // eslint-disable-next-line
  }, [eventId, currency, language, reload]);
  useEffect(() => {
    loadDefaultDates();
    // eslint-disable-next-line
  }, [eventDetails?.created_at]);
  return (
    <Container>
      <ToastContainer
        ref={(reff) => {
          container = reff;
        }}
        className="toast-top-right"
      />
      <NewPageHeader
        title={t("financialStatement.title")}
        subtitle={t("financialStatement.subtitle")}
        children={
          <div className="expenses-button">
            <ButtonContained
              className="primary-contained"
              onClick={() => setIsOpen(true)}
              content={
                <Fragment>
                  {t("financialStatement.recordExpense")} <AddCircle />
                </Fragment>
              }
            />
          </div>
        }
      />
      <Main>
        <ContainersWrapper>
          {loading || isLoading ? (
            <Loading title={t("global:loadingText")} />
          ) : error ? (
            <div>
              <p>{t("global:errors.somethingWentWrong")}</p>
            </div>
          ) : (
            <>
              <FiltersContainer>
                <div className="datepicker-container">
                  <DatePicker
                    label={t("components:datepicker.startDate")}
                    leftIcon
                    onChange={(start) => {
                      loadData(start, dates.endDate);
                      setDates((prev) => ({ ...prev, startDate: start }));
                    }}
                    leftIconChild={<Calendar />}
                    value={dates.startDate}
                    minDate={dates.eventStartDate}
                    maxDate={maxStartDate}
                    borderVariant="rounded"
                    rightIcon
                    rightIconChild={<ChevronDown stroke="#171719" />}
                  />
                </div>
                <div className="datepicker-container">
                  <DatePicker
                    label={t("components:datepicker.endDate")}
                    leftIcon
                    onChange={(end) => {
                      loadData(dates.startDate, end);
                      setDates((prev) => ({ ...prev, endDate: end }));
                    }}
                    leftIconChild={<Calendar />}
                    value={dates.endDate}
                    minDate={dates.startDate}
                    maxDate={new Date()}
                    borderVariant="rounded"
                    rightIcon
                    rightIconChild={<ChevronDown stroke="#171719" />}
                  />
                </div>
                <div>
                  <ButtonContained
                    className="secondary-outlined"
                    id="export-button"
                    onClick={() => exportInXlxs()}
                    content={
                      <Fragment>
                        {isDownloading ? (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="sm"
                          />
                        ) : (
                          <Fragment>
                            <Download2 stroke="#0050C3" />
                            <span className="export-text">
                              {t("financialStatement.export")}
                            </span>
                          </Fragment>
                        )}
                      </Fragment>
                    }
                  />
                </div>
              </FiltersContainer>
              <CollapsibleContainer
                title={t("financialStatement.sales")}
                icon={<TicketIcon />}
              >
                <ContainerContent
                  borderoCardData={sales.headersData}
                  tableData={sales.tables}
                />
              </CollapsibleContainer>
              <CollapsibleContainer
                title={t("financialStatement.deductions")}
                icon={<DeductionIcon />}
              >
                <ContainerContent
                  borderoCardData={deductions.headerData}
                  tableData={deductions.tables}
                />
                <ButtonContained
                  className="primary-link d-flex justify-content-start align-items-center p-0 pb-4"
                  onClick={() => setIsOpen(true)}
                  content={
                    <Fragment>
                      <Add />
                      {t("financialStatement.recordExpense")}
                    </Fragment>
                  }
                />
              </CollapsibleContainer>
              <CollapsibleContainer
                title={t("financialStatement.transfers")}
                icon={<TransferIcon />}
              >
                <ContainerContent
                  borderoCardData={transfers.headersData}
                  tableData={transfers.tables}
                />
              </CollapsibleContainer>
            </>
          )}
        </ContainersWrapper>
        {/* <Summary
          data={{
            totalRevenue: 16213.24,
            totalDeductions: 6590.98,
            netTotal: 9623.26,
            totalAlreadyTransferred: 1501.36,
            pendingToReceive: 8123.9,
          }}
        /> */}
      </Main>
      <DeductionsModal
        expense={expenseInfo}
        isOpen={isOpen}
        setReload={setReload}
        onClose={handleClose}
        toast={container}
      />
    </Container>
  );
};
