import React, { Fragment, useState } from "react";
import "./styles.css";
import { ReactComponent as External } from "../../../../../../assets/icons/external.svg";
import { ReactComponent as Bubble } from "../../../../../../assets/icons/bubble.svg";
import { ReactComponent as ZigLogo } from "../../../../../../assets/icons/zig-logo.svg";
import animationData from "../../../../../../assets/animations/confetti.json";
import Lottie from "react-lottie";
import ButtonContained from "components/buttonContained";

export default function PublishedEvent({ event, id }) {
  const { name, slug } = event;
  const urlMarketplace = process.env.REACT_APP_LINK_EMPRESA;
  const [animationState] = useState({
    isStopped: false,
    isPaused: false,
  });

  const handleRedirectToEventView = () => {
    window.open(`https://${urlMarketplace}/eventos/${slug}`, "_blank");
  };

  const handleRedirectToAdminView = () => {
    window.location.href = `/tickets/dashboard?event_id=${id}`;
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Fragment>
      <div className="h-100 w-100 position-relative d-flex flex-column justify-content-center align-items-center">
        <Lottie
          width={"100vw"}
          height={"100vh"}
          options={defaultOptions}
          isStopped={animationState.isStopped}
          isPaused={animationState.isPaused}
        />
        <div className="text-center position-absolute">
          <div className="d-flex justify-content-center mb-4">
            <Bubble />
          </div>
          <h3 className="title-published">
            Oba! Seu evento {name} foi publicado!
          </h3>
          <p className="information-published">
            Você pode editá-lo quando quiser no seu painel administrativo.
          </p>
          <div className="d-flex justify-content-center align-items-center mt-3 mb-3 ml-5 mr-5 buttons-container-published">
            <ButtonContained
              id="published-see-event-button"
              className="primary-outlined mobile mx-2"
              onClick={handleRedirectToEventView}
              content={
                <Fragment>
                  Ver página do evento <External />
                </Fragment>
              }
            />
            <ButtonContained
              id="published-see-admin-button"
              className="primary-contained mobile mx-2 h-100"
              onClick={handleRedirectToAdminView}
              content={<Fragment>Ir para área administrativa</Fragment>}
            />
          </div>
        </div>
        <div className="logo-container">
          <ZigLogo />
        </div>
      </div>
    </Fragment>
  );
}
