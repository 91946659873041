import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";
import { RiFilePaperLine, RiPencilFill } from "react-icons/ri";
import { components } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";

import ApproveButton from "components/approveButton";
import FileUpload from "components/file-upload";
import ColorfulSelect from "newComponents/colorfulSelect";
import {
  destinyKeys,
  finalityKeys,
  transferTypeAccounts,
} from "services/constants";
import New_api from "services/new-api";
import { formatCurrency } from "utils";

import { ReactComponent as CheckIcon } from "../../../assets/icons/checkCircle.svg";
import { ReactComponent as ClockCircleIcon } from "../../../assets/icons/clockCircleIcon.svg";
import { ReactComponent as DeniedIcon } from "../../../assets/icons/denied.svg";
import { stylesByStatus } from "../transfer-status";

TransferInfos.propTypes = {
  transfer: PropTypes.object,
  statusList: PropTypes.array,
  transactionStatus: PropTypes.object,
  handleTransactionStatus: PropTypes.func,
  handleApproveOrRefuse: PropTypes.func,
  user: PropTypes.object,
  statusOptionsListBuilder: PropTypes.func,
};

export default function TransferInfos({
  onClose,
  transfer,
  onEditTransfer,
  statusList,
  transactionStatus,
  handleTransactionStatus,
  handleApproveOrRefuse,
  currency,
  currencySymbol,
  statusOptionsListBuilder,
}) {
  const statusIcons = {
    "EM ANÁLISE": (
      <ClockCircleIcon
        width={18}
        height={18}
        stroke="#694C00"
        className="mr-2 mb-1"
      />
    ),
    AGUARDANDO: (
      <CheckIcon
        width={20}
        height={20}
        stroke="#00422A"
        className="mr-2 mb-1"
      />
    ),
    PAGO: (
      <CheckIcon
        width={20}
        height={20}
        stroke="#00422A"
        className="mr-2 mb-1"
      />
    ),
    RECUSADO: <DeniedIcon width={18} height={18} className="mr-2 mb-1" />,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customStyles, setCustomStyles] = useState(
    stylesByStatus[transactionStatus?.label]
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const statusOptions = statusList.map(({ value, label }) => ({
    value,
    label,
  }));

  const updateTransferReceiptSchema = yup.object({
    status: yup.string().nullable(),
    receipt: yup
      .string()
      .when("status", (status, field) =>
        status === "edit"
          ? field.required("O comprovante deve ser inserido.")
          : field.nullable()
      ),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(updateTransferReceiptSchema),
  });

  const handleDownloadReceipt = () => {
    window.open(transfer.receipt_link, "_blank");
  };

  const handleEditSuccess = (receiptLink) => {
    const updatedTransfer = {
      ...transfer,
      receipt_link: receiptLink,
    };

    onEditTransfer(updatedTransfer);
  };

  const handleEditReceipt = () => {
    setValue("status", "edit");
    setIsOpen(!isOpen);
  };

  const handleFormSubmit = (form) => {
    setLoading(true);

    New_api.put(`transfers/${transfer.id}`, {
      receipt_link: form.receipt,
    })
      .then(() => {
        Swal.fire({
          title: "Comprovante alterado com sucesso!",
          icon: "success",
          confirmButtonText: "Ok",
        });
        handleEditSuccess(form.receipt);
        onClose();
      })
      .catch(() => {
        Swal.fire({
          title: "Erro ao editar comprovante, tente novamente",
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => setLoading(false));
  };

  const transactionStatusBuilder = ({
    id,
    backgroundColor,
    value,
    ...status
  }) => {
    const transferInfos = {
      requester_user_id: transfer.requester.id,
      event_id: transfer.event.id,
      transfer_transaction_status_id: value.id,
      transfer_transaction_id: transfer.id,
      user_id: user.id,
    };

    statusOptionsListBuilder(status.label);

    handleApproveOrRefuse(status.label, transferInfos);

    setCustomStyles(stylesByStatus[status.label]);

    handleTransactionStatus(status, transferInfos);
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {!!statusIcons[children] && statusIcons[children]}
        {children}
      </components.SingleValue>
    );
  };

  return (
    <Container>
      <Row className="text-center">
        <Col>
          {transactionStatus?.label === "SOLICITADO" && <PendingIcon />}
          {transactionStatus?.label === "PAGO" && (
            <SuccessIcon className="text-success" />
          )}
          {transactionStatus?.label === "RECUSADO" && (
            <RefusedIcon className="text-danger" />
          )}

          <StatusDescription>
            {transactionStatus?.label === "SOLICITADO" &&
              "Essa transferência está aguardando aprovação"}
            {transfer.status === "PAGO" && (
              <span className="text-success">
                Transferência realizada com sucesso
              </span>
            )}
            {transactionStatus?.label === "RECUSADO" && (
              <span className="text-danger">
                Essa transferência foi reprovada
              </span>
            )}
          </StatusDescription>

          <TransferName>{transfer.name}</TransferName>
          <TransferValue>
            {currency !== "EUR" && (
              <TransferCurrencyValue>{currencySymbol} </TransferCurrencyValue>
            )}
            {formatCurrency(Math.round(transfer.amount), {
              isCents: true,
              removeSymbol: true,
              currency,
            })}
            {currency === "EUR" && (
              <TransferCurrencyValue> {currencySymbol}</TransferCurrencyValue>
            )}
          </TransferValue>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <ColorfulSelect
          id="colorful-select-transfer"
          isSearchable={false}
          customStyles={customStyles}
          options={statusOptions}
          value={transactionStatus}
          onChange={transactionStatusBuilder}
          isDisabled={!user.is_admin}
          components={{
            SingleValue,
          }}
        />
      </Row>

      {transactionStatus?.label === "PAGO" && transfer.receipt_link && (
        <Fragment>
          <Row className="justify-content-center">
            <Col md="5">
              <DownloadRecipe
                block
                variant="light"
                onClick={handleDownloadReceipt}
              >
                <RiFilePaperLine className="mr-2" />
                {" baixar comprovante"}
              </DownloadRecipe>
            </Col>
          </Row>
          {user.is_admin === 1 && transactionStatus?.label === "PAGO" && (
            <Fragment>
              <Row className="justify-content-center">
                <Col md="5">
                  <EditTransferReceipt
                    block
                    variant="light"
                    onClick={handleEditReceipt}
                  >
                    <RiPencilFill className="mr-2" />
                    {"editar comprovante"}
                  </EditTransferReceipt>
                </Col>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
      {isOpen && (
        <Form
          className="d-flex flex-column flex-grow-1"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Form.Group>
            <FileUpload
              id="transfer-file"
              url=""
              title="Comprovante"
              label="procurar arquivo..."
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              buttonTitle="buscar"
              description="Você pode inserir imagens ou arquivos PDF."
              containerClassName="mt-2"
              fileTitle="Ver comprovante"
              uploadFolder="transferencias"
              error={errors.receipt?.message}
              onChange={(url) => setValue("receipt", url)}
              onLoading={setUploading}
            />
            {errors.receipt?.message && (
              <div class="invalid-feedback d-block">
                {errors.receipt.message}
              </div>
            )}

            <Form.Control.Feedback type="invalid">
              {errors.refuse_reason?.message}
            </Form.Control.Feedback>

            <Row className="mt-3 row d-flex justify-content-end">
              <div className="col-md-5">
                <ApproveButton
                  type="submit"
                  disabled={uploading || loading}
                  title={loading ? "enviando..." : "editar transferência"}
                />
              </div>
            </Row>
          </Form.Group>
        </Form>
      )}

      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>Pedido feito por:</InfoTitle>
          <InfoValue>
            {`${transfer.requester?.first_name} ${transfer.requester?.last_name}`}{" "}
            - {transfer.requester?.email}
          </InfoValue>

          <InfoTitle>Data e hora da solicitação:</InfoTitle>
          <InfoValue>
            {moment(transfer.created_at).format("DD/MM/yyyy HH:mm:ss")}
          </InfoValue>

          <InfoTitle>Evento:</InfoTitle>
          <InfoValue>{transfer.event?.name || "-"}</InfoValue>
        </Col>
      </Row>
      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>Recebedor:</InfoTitle>
          <InfoValue className="text-capitalize">
            {(transfer.name || "-").toLowerCase()}
          </InfoValue>

          {transfer.document && transfer.document.length > 14 ? (
            <InfoValue className="text-capitalize">
              {`CNPJ: ${(transfer.document || "-").toLowerCase()}`}
            </InfoValue>
          ) : (
            <InfoValue className="text-capitalize">
              {`CPF: ${(transfer.document || "-").toLowerCase()}`}
            </InfoValue>
          )}
          {transfer.method === "PIX" ? (
            <>
              <InfoTitle>Chave PIX:</InfoTitle>
              <InfoValue>
                {transferTypeAccounts[transfer.type_account]} -{" "}
                {transfer.pix_key}
              </InfoValue>
            </>
          ) : (
            <>
              <InfoTitle>Banco:</InfoTitle>
              <InfoValue>
                {transfer.bank?.code} - {transfer.bank?.name}
              </InfoValue>
            </>
          )}
        </Col>
      </Row>

      {transfer.method === "TED" && (
        <Row>
          <Col md="6">
            <InfoTitle>Agência:</InfoTitle>
            <InfoValue>{transfer.agency}</InfoValue>
          </Col>
          <Col md="6">
            <InfoTitle>
              Conta {transferTypeAccounts[transfer.type_account]}:
            </InfoTitle>
            <InfoValue>{transfer.account}</InfoValue>
          </Col>
        </Row>
      )}

      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>Finalidade:</InfoTitle>
          <InfoValue className="text-capitalize">
            {finalityKeys[transfer.finality] || "Não informado"}
          </InfoValue>

          <InfoTitle>Observação:</InfoTitle>
          <InfoValue className="text-capitalize">
            {transfer.observation || "Não informado"}
          </InfoValue>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <InfoTitle>Destino:</InfoTitle>
          <InfoValue className="text-capitalize">
            {destinyKeys[transfer.destiny] || "Não informado"}
          </InfoValue>
        </Col>
      </Row>

      {transfer.last_updated_status ||
        (transfer.refunse_reason && <TransferDivisor />)}

      <Row>
        <Col>
          {transfer.last_updated_status && (
            <>
              <InfoTitle>Realizada em:</InfoTitle>
              <InfoValue>
                {moment(transfer.last_updated_status).format(
                  "DD/MM/yyyy HH:mm:ss"
                )}
              </InfoValue>
            </>
          )}

          {transfer.refunse_reason && (
            <>
              <InfoTitle>Motivo da recusa:</InfoTitle>
              <InfoValue>{transfer.refunse_reason}</InfoValue>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

//------ Styled Components -----//
const Container = styled.div`
  padding: 16px 0 0;
`;

const PendingIcon = styled(FaRegClock)`
  font-size: 31px;
  color: #4f6c7c;
`;

const SuccessIcon = styled(FaRegCheckCircle)`
  font-size: 31px;
`;
const RefusedIcon = styled(FaRegTimesCircle)`
  font-size: 31px;
`;

const StatusDescription = styled.p`
  margin: 0;
  font-weight: 400;
  color: #4f6c7c;
  font-size: 12px;
`;

const TransferName = styled.p`
  margin: 23px 0 0;
  font-size: 15px;
  font-weight: 400;
  color: #8fa5b2;
  text-transform: uppercase;
`;

const TransferValue = styled.p`
  margin: 5px 0 0;
  color: #4f6c7c;
  font-family: "Rubik", sans-serif;
  font-size: 45px;
  line-height: 44px;
`;

const TransferCurrencyValue = styled.span`
  font-size: 20px;
`;

const DownloadRecipe = styled(Button)`
  margin: 10px 0 0;
`;

const EditTransferReceipt = styled(Button)`
  margin: 10px 0 0;
`;

const TransferDivisor = styled.hr`
  margin: 21px 0 0;
  border-top: 2px dashed #bacad3;
`;

const InfoTitle = styled.p`
  color: #4f6c7c;
  font-weight: 500;
  font-size: 15px;
  margin: 14px 0 0;
`;

const InfoValue = styled.p`
  color: #8fa5b2;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
`;
