import React, { useState, useEffect } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWineGlassAlt,
  faBars,
  faChartPie,
  faDollarSign,
  faDatabase,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Line, Doughnut } from "react-chartjs-2";
import DataTable, { createTheme } from "react-data-table-component";
import Api from "services/api.js";
import PageHeader from "components/page-header";
import { colorPicker } from "color-picker.js";
import ReactTooltip from "react-tooltip";
import EmptyData from "components/empty-data";
import moment from "moment-timezone";
import Loading from "components/loading/index.js";
import Swal from "sweetalert2";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

createTheme("ticket", {
  text: {
    primary: "#4F6C7C",
    secondary: "#CBC1BF",
    font: "roboto",
  },
  headertext: {
    primary: "#2F81DA",
  },
  divider: {
    default: "#fff",
  },
  highlightOnHover: {
    default: "#EEEEEE",
    text: "rgba(0, 0, 0, 0.87)",
    divider: {
      default: "#3F25A8",
    },
  },
  button: {
    default: "#3F25A8",
    focus: "#00A7E1",
    hover: "#20C4F4",
    disabled: "rgba(0, 0, 0, 0.12)",
  },
});

const EventDashboard = () => {
  const { noCentsCurrencyFormatter, currency, symbol } = useLanguageSettings();
  const { t } = useTranslation();
  moment.tz.setDefault("America/Sao_Paulo");

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [doubleTypeEvent, setDoubleTypeEvent] = useState(false);
  const [changeCard, setChange] = useState();
  const [fat, setFat] = useState();
  const [, /*stockCost*/ setStockCost] = useState();

  const [equipmentNumbers, setEquipmentNumbers] = useState(0);

  const [arrPayments, setArrPayments] = useState([]);
  const [arrPaymentsAmount, setArrPaymentsAmount] = useState([]);
  const [typePaymentChart, setTypePamentChart] = useState(false);

  const [mostSellers, setMostSellers] = useState([]);
  const [mostSellersGraph, setMostSellersGraph] = useState(false);
  const [mostSellersColor, setMostSellersColor] = useState([]);
  const [mostSellersData, setMostSellersData] = useState([]);
  const [mostSellersLabel, setMostSellersLabel] = useState([]);

  const [fatSellersGraph, setFatSellersGraph] = useState(false);
  const [fatSellersColor, setFatSellersColor] = useState([]);
  const [fatSellersData, setFatSellersData] = useState([]);
  const [fatSellersLabel, setFatSellersLabel] = useState([]);
  const [fatB, setFatB] = useState();
  const [cancel, setCancel] = useState();
  const [, /*total*/ setTotal] = useState();

  const [fatPerHour, setFatPerHour] = useState();
  const [fatPerHourLabel, setFatPerHourLabel] = useState();
  const [directSales, setDirectSales] = useState();
  const [renderTabs, setRenderTabs] = useState();
  // const [typePaymentLabel, setTypePaymentLabel] = useState([]);

  const [dataInit, setDataInit] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [event, setEvent] = useState({
    name: "",
    start_date: "",
    isEstablishment: 0,
    type: "",
  });
  const [dataTransaction, setDataTransaction] = useState({
    cancelamento: {
      total_amount: 0,
      total_transactions: 0,
    },
    ticket: {
      total_amount: 0,
      total_transactions: 0,
    },
    consumo: {
      total_amount: 0,
      total_transactions: 0,
    },
    ativacao: {
      total_amount: 0,
      total_transactions: 0,
    },
    devolucao: {
      total_amount: 0,
      total_transactions: 0,
    },
    desativacao: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoConsumo: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoticket: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoRecarga: {
      total_amount: 0,
      total_transactions: 0,
    },
    recarga: {
      total_amount: 0,
      total_transactions: 0,
    },
  });
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("initial");

  function showGraphTypePayment(state) {
    if (typePaymentChart !== state) {
      setTypePamentChart(state);
    }
    var chartBtn1 = document.getElementById("btn1-chartpayment");
    var chartBtn2 = document.getElementById("btn2-chartpayment");

    if (state === false) {
      if (chartBtn1.classList.contains("chartbtn-active")) {
      } else {
        chartBtn2.classList.remove("chartbtn-active");
        chartBtn1.classList.add("chartbtn-active");
      }
    } else {
      if (chartBtn2.classList.contains("chartbtn-active")) {
      } else {
        chartBtn1.classList.remove("chartbtn-active");
        chartBtn2.classList.add("chartbtn-active");
      }
    }
  }

  function showGraphMostSeller(state) {
    if (mostSellersGraph !== state) {
      setMostSellersGraph(state);
    }
  }

  function showGraphFatSeller(state) {
    if (fatSellersGraph !== state) {
      setFatSellersGraph(state);
    }
  }

  const columns = [
    {
      width: "100px",
      cell: (row) => (
        <img className="img-product-dash" alt="" src={row.picture}></img>
      ),
    },
    {
      name: "Nome",
      selector: "name",
    },
    {
      name: "Quantidade",
      selector: "quantity",
    },
  ];

  const columns2 = [
    {
      width: "100px",
      cell: (row) => (
        <img className="img-product-dash" alt="" src={row.picture}></img>
      ),
    },
    {
      name: "Nome",
      selector: "name",
    },
    {
      name: "Faturamento",
      selector: "unique_price",
      cell: (row) => <span>{noCentsCurrencyFormatter(row.total)}</span>,
    },
  ];

  function generateColors(numColors) {
    var colors = [];
    for (var i = 0; i < numColors; i++) {
      colors.push(getRandomColor());
    }
    return colors;
  }

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const [selectableRows] = useState(false);
  const [noSelectAll] = useState(false);
  const [selectableRowsHighlight] = useState(false);
  const [expandableRows] = useState(false);
  const [expandOnRowClick] = useState(false);
  const [pagination] = useState(true);
  const [highlight] = useState(false);
  const [striped] = useState(false);
  const [pointer] = useState(false);
  const [dense] = useState(false);
  const [persist] = useState(false);
  const [tableHead] = useState(false);
  const [loading] = useState(false);
  const [noHeader] = useState(true);
  const [subHeader] = useState(false);
  const [subHeaderAlign] = useState("right");
  const [fixedHeader] = useState(false);

  const dashboard = (typeEventSelected) => {
    let colorsMostSellers;
    document.getElementById("loading").classList.remove("esconde");
    if (search === "initial" || search === "clicked" || typeEventSelected) {
      let inicio =
        dataInit !== ""
          ? moment(dataInit)
              .tz("America/Sao_Paulo")
              .format("YYYY-MM-DD HH:mm:ss")
          : null;
      let fim =
        dataFim !== ""
          ? moment(dataFim)
              .tz("America/Sao_Paulo")
              .format("YYYY-MM-DD HH:mm:ss")
          : null;

      Api.get(`dash/getEvent/${event_id}`)
        .then((res) => {
          const typeEvent = typeEventSelected
            ? typeEventSelected
            : res.data.type;

          changeTypeEvent(typeEvent !== "ticket" ? "cashless" : "ticket");

          //Donut Valor tipo de pagamento
          Api.post(`dash/getPaymentAmount/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          })
            .then((res) => {
              setArrPaymentsAmount(res.data);
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            });
          setEvent({
            name: res.data.name,
            start_date: res.data.start_date,
            isEstablishment: res.data.isEstablishment,
            type: typeEvent,
          });

          //Faturamento dos produtos
          Api.post(`dash/getFat/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          })
            .then((res) => {
              setFat(res.data.total);
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            });

          //Cancelamentos
          Api.post(`dash/getCancel/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            setCancel(res.data.total);
          });

          //Custo de estoque
          Api.get(`dash/getStockCost/${event_id}`).then((res) => {
            setStockCost(res.data);
          });

          //Numero de Equipametnos Sincronizados
          Api.post(`dash/getEquipmentsNumbers/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            setEquipmentNumbers(res.data[0].quantity);
          });

          //Donut tipo pagamento
          Api.post(`dash/getPaymentType/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            setArrPayments(res.data);
          });

          //Quantidade de produtos vendidos .
          Api.post(`dash/getQuantity/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            setQuantity(res.data.quantity);
          });

          //Produtos mais vendidos
          Api.post(`dash/getMostSellerProducts/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            setMostSellers(res.data);
            // ####QUANTIDADE POR PRODUTO ! #####
            let auxdatasellers = res.data.map((data) => data.quantity);
            setMostSellersData(auxdatasellers);
            //PEGANDO CORES DO COLOR PICKER
            colorsMostSellers = colorPicker(auxdatasellers.length);
            const auxColors = colorsMostSellers.map((data) => data);
            setMostSellersColor(auxColors);
            const auxdatasellerslabel = res.data.map((data) => data.name);
            setMostSellersLabel(auxdatasellerslabel);
            //Pegando label pro gráfico
            let auxdatasellers2 = res.data.map((data) => data.total);
            setFatSellersData(auxdatasellers2);
            colorsMostSellers = colorPicker(auxdatasellers2.length);
            const auxColors2 = colorsMostSellers.map((data) => data);
            setFatSellersColor(auxColors2);
            // #### FATURAMENTO POR PRODUTO ! #####
            //PEGANDO CORES DO COLOR PICKER
            //Pegando label pro gráfico
            const auxdatasellerslabel2 = res.data.map((data) => data.name);
            setFatSellersLabel(auxdatasellerslabel2);
            //LUCRO
            let auxLucro = res.data.total + res.data.total - res.data;
            setTotal(auxLucro);
          });

          //FATURAMENTOHORA

          Api.post(`dash/getFatPerHour/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          }).then((res) => {
            const dadosFatHour = res.data?.salesByDayAndTime?.map((d) => {
              return d.amount;
            });
            const labelsFatHour = res.data?.salesByDayAndTime?.map((d) => {
              return `${d.hour}:00 - ${moment(d.date).format("DD/MM")}`;
            });
            setFatPerHourLabel(labelsFatHour);
            setFatPerHour(dadosFatHour);
            setDirectSales(res.data.hoursTotal);
          });

          Api.post(`dash/getTransactionsType/${event_id}`, {
            dataInit: inicio,
            dataFim: fim,
            type: typeEvent,
          })
            .then((res) => {
              setEvent({
                ...event,
                type: res.data.eventType,
              });

              const verifyDoubleTypeEvent =
                !!res.data?.objectTypes?.ticket &&
                !!res.data?.objectTypes?.consumo
                  ? true
                  : false;

              setDoubleTypeEvent(verifyDoubleTypeEvent);

              setDataTransaction({
                ticket: {
                  total_amount:
                    res.data?.objectTypes?.ticket?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.ticket?.total_transactions || 0,
                },
                estornoticket: {
                  total_amount:
                    res.data?.objectTypes?.estornoticket?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.estornoticket?.total_transactions ||
                    0,
                },
                cancelamento: {
                  total_amount:
                    res.data?.objectTypes?.cancelamento?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.cancelamento?.total_transactions ||
                    0,
                },
                ativacao: {
                  total_amount:
                    res.data?.objectTypes?.ativacao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.ativacao?.total_transactions || 0,
                },
                consumo: {
                  total_amount:
                    res.data?.objectTypes?.consumo?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.consumo?.total_transactions || 0,
                },
                devolucao: {
                  total_amount:
                    res.data?.objectTypes?.devolucao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.devolucao?.total_transactions || 0,
                },
                desativacao: {
                  total_amount:
                    res.data?.objectTypes?.desativacao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.desativacao?.total_transactions || 0,
                },
                estornoConsumo: {
                  total_amount:
                    res.data?.objectTypes?.estornoconsumo?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.estornoconsumo?.total_transactions ||
                    0,
                },
                estornoRecarga: {
                  total_amount:
                    res.data?.objectTypes?.estornorecarga?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.estornorecarga?.total_transactions ||
                    0,
                },
                recarga: {
                  total_amount:
                    res.data?.objectTypes?.recarga?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.recarga?.total_transactions || 0,
                },
              });
            })
            .finally(() =>
              document.getElementById("loading").classList.add("esconde")
            );
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
    setSearch("noSearch");
  };

  useEffect(() => {
    if (event_id || search) {
      dashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id, search]);

  useEffect(() => {
    setFatB(fat - cancel);
    // eslint-disable-next-line
  }, [fat]);

  useEffect(() => {
    let calc1 =
      dataTransaction.consumo?.total_amount +
      dataTransaction.devolucao?.total_amount;
    let calc2 = dataTransaction.recarga?.total_amount;

    setValue(calc2 - calc1);
  }, [dataTransaction]);

  const chartColors = generateColors(arrPaymentsAmount.length);

  const changeTypeEvent = (type) => {
    setChange(type);
    setRenderTabs(
      <div className="menu col-12 mt-4">
        <div className="nav justify-content-center row">
          <div
            id="type-nav-ticket"
            className={`nav-item col-6 type-nav ${
              type === "ticket" ? "active" : ""
            } col-lg-3 mr-4`}
            onClick={() => {
              dashboard("ticket");
            }}
          >
            <button id="buttonCard-1" aria-current="page" data-toggle="pill">
              Ticket
            </button>
          </div>
          <div
            id="type-nav-cashless"
            className={`nav-item col-6 type-nav ${
              type === "cashless" ? "active" : ""
            } col-lg-3 ml-2 mr-2`}
            onClick={() => {
              dashboard("cashless");
            }}
          >
            <button id="buttonCard-2" data-toggle="pill">
              Cashless
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title={event.isEstablishment ? "Dashboard de Vendas" : "Dashboard"}
        text="Essa é a página inicial das suas vendas de alimento e bebida"
      ></PageHeader>
      <Loading title="aguarde..." />
      <div className="mt-4 container-fluid">
        <ReactTooltip place="top" className="tooltip-custom" effect="solid" />
        <div id="name-event">
          <div className="row">
            <div className="col-md-5 filter-datepicker">
              <DatePicker
                name="data-ini"
                showTimeSelect
                value={dataInit}
                onChange={(date) => setDataInit(date)}
                placeholderText={t("components:datepicker.startDate2")}
              />
            </div>
            <div className="col-md-5 filter-datepicker">
              <DatePicker
                name="data-fim"
                showTimeSelect
                value={dataFim}
                onChange={(date) => setDataFim(date)}
                placeholderText={t("components:datepicker.endDate2")}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-secondary"
                onClick={(e) => setSearch("clicked")}
              >
                Pesquisar
              </button>
            </div>
          </div>
        </div>
        {doubleTypeEvent && renderTabs}
        <div id="cards" className="mt-3">
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="card-one cards">
                <p
                  className="card-span"
                  data-tip="Total de vendas - Total de estornos"
                >
                  {noCentsCurrencyFormatter(fatB)}
                </p>

                <p className="card-subspan">
                  {event.isEstablishment
                    ? "vendas no período"
                    : "Pagamentos no periodo"}
                </p>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card-two cards">
                <p
                  className="card-span"
                  data-tip="Quantidade total de produtos vendidos"
                >
                  {quantity ? quantity : "0"}
                </p>
                <p className="card-subspan">produtos vendidos</p>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card-three cards">
                <p
                  className="card-span"
                  data-tip="Número de Equipamentos já utilizados"
                >
                  {equipmentNumbers ? equipmentNumbers : "0"}
                </p>
                <p className="card-subspan">equipamentos já sincronizados</p>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card-four cards">
                <p className="card-span">{directSales}</p>
                <p className="card-subspan">
                  {directSales > 1 ? "horas" : "hora"} de venda direta
                </p>
              </div>
            </div>
          </div>
        </div>

        {changeCard === "ticket" && (
          <div id="values" className="borders-values mt-3">
            {/* CARDS NOVOS - CASHLESS */}
            <div className="row p-4">
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#4F6C7C" }}>
                    {noCentsCurrencyFormatter(
                      dataTransaction.ticket?.total_amount
                        ? dataTransaction.ticket?.total_amount
                        : 0
                    )}
                  </p>
                  <p
                    className="values-span text-small"
                    style={{ color: "#4F6C7C" }}
                  >
                    {`Quantidade: ${
                      dataTransaction.ticket?.total_transactions
                        ? dataTransaction.ticket?.total_transactions
                        : 0
                    }`}
                  </p>
                  <p className="values-subspan" style={{ color: "#4F6C7C" }}>
                    {"vendas realizadas"}
                  </p>
                </div>
              </div>
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#D9277A" }}>
                    {`- ${noCentsCurrencyFormatter(
                      dataTransaction.cancelamento?.total_amount
                        ? dataTransaction.cancelamento?.total_amount
                        : 0
                    )}`}
                  </p>
                  <p
                    className="values-span text-small"
                    style={{ color: "#D9277A" }}
                  >
                    {`Quantidade: ${
                      dataTransaction.cancelamento?.total_transactions
                        ? dataTransaction.cancelamento?.total_transactions
                        : 0
                    }`}
                  </p>
                  <p className="values-subspan" style={{ color: "#D9277A" }}>
                    {"cancelamentos no período"}
                  </p>
                </div>
              </div>

              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#00a7e1" }}>
                    {noCentsCurrencyFormatter(
                      dataTransaction.ticket?.total_amount
                        ? dataTransaction.ticket?.total_amount -
                            dataTransaction.cancelamento?.total_amount
                        : 0
                    )}
                  </p>
                  <p className="values-subspan" style={{ color: "#00a7e1" }}>
                    {"total bruto"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {changeCard === "cashless" && (
          <div id="values" className="borders-values mt-3">
            <div className="row p-4">
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#4F6C7C" }}>
                    {noCentsCurrencyFormatter(
                      dataTransaction.recarga?.total_amount
                    )}
                  </p>
                  <p
                    className="values-span text-small"
                    style={{ color: "#4F6C7C" }}
                  >
                    {`Quantidade: ${dataTransaction.recarga?.total_transactions}`}
                  </p>
                  <p className="values-subspan" style={{ color: "#4F6C7C" }}>
                    {"Recargas/Pagamentos realizados"}
                  </p>
                </div>
              </div>
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#4F6C7C" }}>
                    {noCentsCurrencyFormatter(
                      dataTransaction.ativacao?.total_amount -
                        dataTransaction.desativacao?.total_amount
                    )}
                  </p>
                  <p
                    className="values-span text-small"
                    style={{ color: "#4F6C7C" }}
                  >
                    {`Quantidade: ${
                      dataTransaction.ativacao?.total_transactions -
                      dataTransaction.devolucao?.total_transactions
                    }`}
                  </p>
                  <p className="values-subspan" style={{ color: "#4F6C7C" }}>
                    {"Chips ativos"}
                  </p>
                </div>
              </div>
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#D9277A" }}>
                    {`- ${noCentsCurrencyFormatter(
                      dataTransaction.consumo?.total_amount
                    )}`}
                  </p>
                  <p
                    className="values-span text-small"
                    style={{ color: "#D9277A" }}
                  >
                    {`Quantidade: ${dataTransaction.consumo?.total_transactions}`}
                  </p>
                  <p className="values-subspan" style={{ color: "#D9277A" }}>
                    {"Consumo realizados"}
                  </p>
                </div>
              </div>
              <div className="col-md">
                <div className="value-div">
                  <p className="values-span" style={{ color: "#D9277A" }}>
                    {`- ${noCentsCurrencyFormatter(
                      dataTransaction.devolucao?.total_amount
                    )}`}
                  </p>
                  <p className="values-subspan" style={{ color: "#D9277A" }}>
                    {"Devoluções"}
                  </p>
                </div>
              </div>
              <div className="col-md">
                <div className="value-div">
                  <p className="card-span"></p>

                  <p className="values-span" style={{ color: "#00FF7F" }}>
                    {noCentsCurrencyFormatter(Math.abs(value))}
                  </p>
                  <p className="values-subspan" style={{ color: "#00FF7F" }}>
                    {"Saldo Periodo"}
                    <span className="tips">
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <div className="tips-content">
                        Recarga(+)Ativação(-)Consumo(-)Devolução
                      </div>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="charts" className="mt-3">
          <div className="row p-2">
            <div className="col-md-8">
              <div className="card-chart">
                <div className="card-chart-header">
                  <p className="subtitle">Faturamento</p>
                </div>
                <div id="dashboard-chart-billing" className="card-chart-body">
                  <Line
                    className="adjustment-grafico"
                    data={{
                      labels: fatPerHourLabel,
                      datasets: [
                        {
                          label: "Faturamento",
                          data: fatPerHour,
                          backgroundColor: "rgb(54, 162, 235)",
                          borderColor: "rgb(54, 162, 235)",
                          fill: false,
                        },
                      ],
                    }}
                    width={500}
                    height={250}
                    options={{
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true,
                          },
                        },
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var label =
                              data.datasets[tooltipItem.datasetIndex].data[
                                tooltipItem.index
                              ] || "";

                            if (label) {
                              label = label.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              });
                            }

                            return label;
                          },
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: function (label) {
                              return currency === "EUR"
                                ? `${label} ${symbol}`
                                : `${symbol} ${label}`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-chart">
                <div className="card-chart-header">
                  <div className="row">
                    <div className="col-md-12 chart-header-content">
                      <span className="subtitle">Pagamentos</span>
                      <div>
                        <button
                          id="btn1-chartpayment"
                          className="btn btn-charts mr-2 chartbtn-active"
                          onClick={(e) => showGraphTypePayment(false)}
                        >
                          <FontAwesomeIcon icon={faDatabase} /> por quantidade
                        </button>
                        <button
                          id="btn2-chartpayment"
                          className="btn btn-charts"
                          onClick={(e) => showGraphTypePayment(true)}
                        >
                          <FontAwesomeIcon icon={faDollarSign} /> por preço
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="dashboard-chart-payment" className="card-chart-body">
                  {typePaymentChart ? (
                    <Doughnut
                      className="adjustment-grafico"
                      data={{
                        labels: arrPaymentsAmount.map((item) => item.name),
                        datasets: [
                          {
                            label: "Vendas",
                            backgroundColor: chartColors,
                            borderColor: chartColors,
                            data: arrPaymentsAmount.map((item) => item.sold),
                          },
                        ],
                      }}
                      width={500}
                      height={500}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              usePointStyle: true,
                              padding: 10,
                            },
                          },
                        },
                        cutout: "60%",
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              var label =
                                data.datasets[tooltipItem.datasetIndex].data[
                                  tooltipItem.index
                                ] || "";

                              if (label) {
                                label =
                                  data.labels[tooltipItem.index] +
                                  " " +
                                  label.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  });
                              }

                              return label;
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <Doughnut
                      className="adjustment-grafico"
                      data={{
                        labels: arrPayments.map((item) => item.name),
                        datasets: [
                          {
                            label: "Vendas",
                            backgroundColor: chartColors,
                            borderColor: chartColors,
                            data: arrPayments.map((item) => item.total),
                          },
                        ],
                      }}
                      width={500}
                      height={500}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              usePointStyle: true,
                              padding: 10,
                            },
                          },
                        },
                        cutout: "60%",
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              var label =
                                data.datasets[tooltipItem.datasetIndex].data[
                                  tooltipItem.index
                                ] || "";

                              if (label) {
                                label =
                                  data.labels[tooltipItem.index] + ": " + label;
                              }

                              return label;
                            },
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="products" className="mt-3">
          <div className="row p-2">
            <div className="col-md-6">
              <div className="card-chart">
                <div className="card-chart-header">
                  <div className="row">
                    <div className="col-md-12 chart-header-content">
                      <span className="subtitle">
                        <FontAwesomeIcon icon={faWineGlassAlt} /> Produtos mais
                        vendidos
                      </span>
                      <div>
                        <button
                          className="btn btn-charts mr-2"
                          onClick={(e) => showGraphMostSeller(false)}
                        >
                          <FontAwesomeIcon
                            icon={faBars}
                            style={{ margin: 0 }}
                          />
                        </button>
                        <button
                          className="btn btn-charts"
                          onClick={(e) => showGraphMostSeller(true)}
                        >
                          <FontAwesomeIcon
                            icon={faChartPie}
                            style={{ margin: 0 }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-chart-body">
                  {mostSellersGraph ? (
                    <Doughnut
                      data={{
                        labels: mostSellersLabel,
                        datasets: [
                          {
                            label: "Vendas",
                            backgroundColor: mostSellersColor,
                            data: mostSellersData,
                          },
                        ],
                      }}
                      width={500}
                      height={350}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={mostSellers}
                      defaultSortField="quantity"
                      defaultSortAsc={false}
                      selectableRows={selectableRows}
                      selectableRowsNoSelectAll={noSelectAll}
                      selectableRowsHighlight={selectableRowsHighlight}
                      expandableRows={expandableRows}
                      paginationPerPage={5}
                      expandOnRowClicked={expandOnRowClick}
                      pagination={mostSellers.length > 5 ? pagination : false}
                      highlightOnHover={highlight}
                      striped={striped}
                      pointerOnHover={pointer}
                      dense={dense}
                      noTableHead={tableHead}
                      persistTableHead={persist}
                      progressPending={loading}
                      noHeader={noHeader}
                      overflowY={false}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: "de",
                      }}
                      noDataComponent={
                        <EmptyData title="Nenhum resultado disponível." />
                      }
                      subHeader={subHeader}
                      theme="ticket"
                      subHeaderAlign={subHeaderAlign}
                      fixedHeader={fixedHeader}
                      fixedHeaderScrollHeight="300px"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-chart">
                <div className="card-chart-header">
                  <div className="row">
                    <div className="col-md-12 chart-header-content">
                      <span className="subtitle">
                        <FontAwesomeIcon icon={faWineGlassAlt} /> Faturamento
                        por produto
                      </span>
                      <div>
                        <button
                          className="btn btn-charts mr-2"
                          onClick={(e) => showGraphFatSeller(false)}
                        >
                          <FontAwesomeIcon
                            icon={faBars}
                            style={{ margin: 0 }}
                          />
                        </button>
                        <button
                          className="btn btn-charts"
                          onClick={(e) => showGraphFatSeller(true)}
                        >
                          <FontAwesomeIcon
                            icon={faChartPie}
                            style={{ margin: 0 }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-chart-body">
                  {fatSellersGraph ? (
                    <Doughnut
                      data={{
                        labels: fatSellersLabel,
                        datasets: [
                          {
                            label: "Vendas",
                            backgroundColor: fatSellersColor,
                            data: fatSellersData,
                          },
                        ],
                      }}
                      width={500}
                      height={350}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              var label =
                                data.datasets[tooltipItem.datasetIndex].data[
                                  tooltipItem.index
                                ] || "";

                              if (label) {
                                label =
                                  data.labels[tooltipItem.index] +
                                  " " +
                                  label.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  });
                              }

                              return label;
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <DataTable
                      columns={columns2}
                      data={mostSellers}
                      defaultSortField="total"
                      selectableRows={selectableRows}
                      selectableRowsNoSelectAll={noSelectAll}
                      selectableRowsHighlight={selectableRowsHighlight}
                      expandableRows={expandableRows}
                      paginationPerPage={5}
                      expandOnRowClicked={expandOnRowClick}
                      pagination={mostSellers.length > 5 ? pagination : false}
                      highlightOnHover={highlight}
                      striped={striped}
                      pointerOnHover={pointer}
                      dense={dense}
                      defaultSortAsc={false}
                      noTableHead={tableHead}
                      persistTableHead={persist}
                      progressPending={loading}
                      noHeader={noHeader}
                      overflowY={false}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: "de",
                      }}
                      noDataComponent={
                        <EmptyData title="Nenhum resultado disponível." />
                      }
                      subHeader={subHeader}
                      theme="ticket"
                      subHeaderAlign={subHeaderAlign}
                      fixedHeader={fixedHeader}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDashboard;
