import { ToolTip } from "components/tooltip";
import { Container } from "./styles";
import React from "react";

export const BorderoCard = ({
  amount,
  description,
  tooltip,
  isTransferPage = false,
}) => {
  return (
    <Container isTransferPage={isTransferPage}>
      {isTransferPage ? (
        <>
          <div className="tp-container">
            {description}
            {tooltip && <ToolTip tooltip={tooltip} />}
          </div>
          <h3>{amount}</h3>
        </>
      ) : (
        <>
          <h3>{amount}</h3>
          <div className="tp-container">
            {description}
            {tooltip && <ToolTip tooltip={tooltip} />}
          </div>
        </>
      )}
    </Container>
  );
};
